import React from 'react'


// Import Icons
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

// Import Styling Page
import style from '../styles/About'


// Import Material UIs
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';
import Navbar from '../components/Navbar';



export default function Services({isAuth}) {
  return (
    <div>

        <Navbar isAuth={isAuth} />
        {/* Promotional Space 2 */}
        <Box sx={style.promotionalSpaceContainer}>
                    {/* title */}
                    <Box sx={style.titleContainer}>
                        <Typography sx={style.promotionalTitle}>Services We Offer</Typography>
                        <Typography sx={style.promotionalTitleSub}> <br /> </Typography>
                    </Box>

                    <Grid container sx={style.serviceGridContainer}>
                        {/* Column 1 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <LocalHospitalIcon sx={style.checkIcon} />
                                <Typography sx={style.serviceTitle}>Check-ups</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>

                        {/* Column 2 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <PregnantWomanIcon sx={style.pregnantIcon} />
                                <Typography sx={style.serviceTitle}>Prenatal Check-ups</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>

                        {/* Column 3 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <VaccinesIcon sx={style.medicineIcon} />
                                <Typography sx={style.serviceTitle}>Immunization</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>

                        {/* Column 4 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <FamilyRestroomIcon sx={style.familyIcon} />
                                <Typography sx={style.serviceTitle}>Family Planning</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
    </div>
  )
}
