import React from 'react'

import Navbar from '../components/Navbar'
import Footer from "../components/Footer";
import style from '../styles/Contact'

// Import Material UIs
import {
    Box,
    Typography,
    Grid,
} from "@mui/material";

// Icons
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';



function Contact({ isAuth }) {
    return (
        <div>
            <Box>
                {/* Navbar */}
                <Navbar isAuth={isAuth} />

                {/* Main Form */}
                <Box sx={style.mainContainer}>

                    <Box>
                        {/* title */}
                        <Box sx={style.titleContainer}>
                            <Typography sx={style.promotionalTitle}>Get in Touch</Typography>
                            <Typography sx={style.promotionalTitleSub}></Typography>
                        </Box>

                    </Box>

                    <Box sx={style.contactContainer}>
                        <Grid container sx={style.contactMainContainer}>

                            {/* per item */}
                            <Grid item sx={style.perItem}>
                                <Box>
                                    <PlaceIcon sx={style.iconMui} />
                                    <Typography sx={style.contactTitle}>
                                        Address
                                    </Typography>
                                    <Typography sx={style.contactInfo}>
                                       Niugan Angat Bulacan
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* per item */}
                            <Grid item sx={style.perItem}>
                                <Box>
                                    <CallIcon sx={style.iconMui} />
                                    <Typography sx={style.contactTitle}>
                                        Contact
                                    </Typography>
                                    <Typography sx={style.contactInfo}>
                                        0967 844 1716 | (044) 837 4421 <br />
                                        Weekdays 08:00am - 05:00am
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* per item */}
                            <Grid item sx={style.perItem}>
                                <Box>
                                    <EmailIcon sx={style.iconMui} />
                                    <Typography sx={style.contactTitle}>
                                        Email
                                    </Typography>
                                    <Typography sx={style.contactInfo}>
                                        niugan.clinic@gmail.com
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item sx={style.perItem}>
                                <Box>
                                    <FacebookIcon sx={style.iconMui} />
                                    <Typography sx={style.contactTitle}>
                                        Facebook Page
                                    </Typography>
                                    <Typography sx={style.contactInfo}>
                                    <a href="https://web.facebook.com/media/set/?set=a.109780373278398&type=3">Barangay Niugan</a>
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>

                </Box>

                {/* Footer */}
                <Footer />
            </Box>
        </div>
    )
}

export default Contact