const style = {
    mainFooterContainer: {
        backgroundColor: '#1a1a1a',
        padding: '50px 0px 50px 0px',
    },

    stuffContainer: {
        width: '80%',
        margin: 'auto',
    },

    logoBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
    },

    logoHealth: {
        display: 'flex',
        marginBottom: '10px',
        alignItems: 'center',
    },

    footerLogoTextBig: {
        fontFamily: 'semibold',
        fontSize: '14px',
        textTransform: 'uppercase',
        color: '#fff',
        textAlign: 'center',
    },

    footerLogoTextSmall: {
        fontFamily: 'poppins',
        fontSize: '12px',
        color: '#fff',
        textAlign: 'center',
    },

    footerLinkText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#8a8a8a',
        lineHeight: '35px',

        '&:hover': {
            color: '#fff',
        },
    },

    footerTitle: {
        fontFamily: 'bold',
        color: '#fff',
        fontSize: '20px',
        marginBottom: '10px',
    },

    footerPlainText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#8a8a8a',
        lineHeight: '35px',
        textAlign: {
            xs: 'center',
            md: 'left',
        },
    },

    footerBox: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
            xs: 'center',
            md: 'flex-start'
        },
    },
}
export default style;