import React from "react";

// Importing Other Page Component
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";
// Import Styling Page
import style from "../styles/Home";

// Import Image
import nurse from "../image/nurse.jpg";
import immune from "../image/immune.jpg";
import prenatal from "../image/prenatal.jpg";
import familyplanning from "../image/familyplanning.jpg";
import checkup from "../image/checkup.jpg";
import comment from "../image/comment.jpg";
import bitoy from "../image/nurse.jpg";
import coco from "../image/staffs.jpg";

// Import Material UIs
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CardActionArea,
  Avatar,
} from "@mui/material";

// Awesome Slider
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "../styles/slider.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";

//redux
import { useSelector } from "react-redux";
function Home({ isAuth }) {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  console.log(isAuth);

  return (
    <div>
      {/* Navigation Bar */}
      <Navbar isAuth={isAuth} />
      <br />
      <br />
      <br />
      {/* Main Body Start */}
      <Box sx={style.mainBody}>
        {/* Banner */}
        <Box sx={style.banner}>
          <Box sx={style.bannerTextContainer}>
            <Typography sx={style.bannerTextBig}>
              Barangay Niugan <br /> Health Center Services
            </Typography>
            <Typography sx={style.bannerTextSmall}>
              Power to heal, Passionate to help. With us, you feel secure,
              Keeping <br /> you to a better life.
            </Typography>

            <Button
              variant="contained"
              sx={style.appButton}
              onClick={() => history.push("/appointment")}
            >
              Schedule an Appointment
            </Button>
          </Box>
        </Box>

        {/* Promotional Space 1 */}
        <Box sx={style.promotionalSpaceContainer}>
          <Grid container>
            {/* Promotional Picture */}
            <Grid item xs={12} sm={12} md={6} lg={6} sx={style.gridPic}>
              <Box>
                <img
                  alt="nurse"
                  src={nurse}
                  style={{
                    width: "400px",
                    height: "400px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>

            {/* Promotional Text */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={style.promotionalTextContainer}>
                <Typography sx={style.promotionalTextBig}>
                  How to schedule an appointment?
                </Typography>

                <Typography sx={style.promotionalTextSmall}>
                 Step 1. You need to Sign In First
                </Typography>

                <Typography sx={style.promotionalTextSmall}>
                Step 2.  In the upper part of the website click "Appointment"
                </Typography>

                <Typography sx={style.promotionalTextSmall}>
                Step 3. Click Get Appointment and fill up the form
                </Typography>

                <Typography sx={style.promotionalTextSmall}>
                Step 4. Wait for the text message that you will Received
                </Typography>

                <Button
                  onClick={()=>history.push("/about")}
                  variant="contained" sx={style.mauButton}>
                  More About Us
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Promotional Space 2 */}
        <Box sx={style.promotionalSpaceContainer}>
          {/* title */}
          <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>
              Services Available
            </Typography>
            <Typography sx={style.promotionalTitleSub}>
              {" "}
              <br />
            </Typography>
          </Box>

          <Box sx={style.servicesOfferedContainer}>
            <Grid container sx={style.servicesContainer}>
              {/* per Item */}
              <Grid item xs={12} sm={12} md={6} lg={6} sx={style.gridService}>
                <Box sx={style.boxCard}>
                  <Card sx={style.cardServices}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={prenatal}
                      alt="prenatal"
                    />

                    <Box>
                      <CardContent>
                        <Typography sx={style.cardTitleService}>
                          Prenatal Check-ups
                        </Typography>
                        <Typography sx={style.cardDescriptionService}>
                          Having a healthy pregnancy is one of the best ways to promote a healthy birth. Getting early and regular prenatal care improves the chances of a healthy pregnancy. This care can begin even before pregnancy with a pre-pregnancy care visit to a health care provider.
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>

              {/* per Item */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={style.boxCard}>
                  <Card sx={style.cardServices}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={immune}
                      alt="immune"
                    />

                    <Box>
                      <CardContent>
                        <Typography sx={style.cardTitleService}>
                          Immunization
                        </Typography>
                        <Typography sx={style.cardDescriptionService}>
                          Vaccines can prevent infectious diseases that once killed or harmed many infants, children, and adults. Without vaccines, your child is at risk for getting seriously ill and suffering pain, disability, and even death from diseases like measles and whooping cough.
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>

              {/* per Item */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={style.boxCard}>
                  <Card sx={style.cardServices}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={familyplanning}
                      alt="familyplanning"
                    />

                    <Box>
                      <CardContent>
                        <Typography sx={style.cardTitleService}>
                          Family Planning
                        </Typography>
                        <Typography sx={style.cardDescriptionService}>
                          Family planning can significantly reduce the risk of maternal, newborn, infant, and child illness and death by preventing a high-risk pregnancy in women with certain health conditions or characteristics, or by preventing an unplanned pregnancy. Women typically welcome pregnancy and childbirth, especially when planned
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>

              {/* per Item */}
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={style.boxCard}>
                  <Card sx={style.cardServices}>
                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={checkup}
                      alt="checkup"
                    />

                    <Box>
                      <CardContent>
                        <Typography sx={style.cardTitleService}>
                          Health Check-ups
                        </Typography>
                        <Typography sx={style.cardDescriptionService}>
                          Regular medical checkups allow doctors to pick up signs of diseases that the patients may not even know to exist. With routine screenings, the doctor can diagnose potentially severe or life-threatening conditions and prevent any kinds of risks before it is too late or it takes a turn for the worse.
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Promotional Space 3 */}
        <Box sx={style.promotionalSpaceContainer}>
          {/* title */}
          <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>
              Latest News and Updates in Barangay Niugan Appear here
            </Typography>
            <Typography sx={{fontSize: 18}}>
              Announcement also posted in our Facebook Page<br />
              <a href="https://web.facebook.com/media/set/?set=a.109780373278398&type=3" >Barangay Niugan Health Center</a>
            </Typography>
            <Typography sx={style.promotionalTitleSub}>
              {" "}
              <br />
            </Typography>
          </Box>

          {/* Cards - News and Updates*/}
          <Grid container sx={style.newsContainer}>
            {/* Per News Item */}
            {user.news.map((item, index) => (
              <Grid item key={index}>
                <Box sx={style.boxCard}>
                  <Card sx={style.cardMain}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="180"
                        image={item.Image}
                        alt={item.Title}
                      />

                      <CardContent>
                        <Typography
                          gutterBottom
                          component="div"
                          sx={style.cardTitle}
                        >
                          {item.Title}
                        </Typography>
                        <Typography sx={style.cardDescription}>
                          {item.Description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Promotional Space 4 */}
        <Box sx={style.promotionalSpaceContainer}>
          <Box>
          <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>
              Barangay Niugan Health Center Staffs
            </Typography>
            <Typography sx={style.promotionalTitleSub}>
              {" "}
              <br />
            </Typography>
          </Box>
            <AutoplaySlider
              className="aws-btn"
              bullets={false}
              play={false}
              interval={20000}
            >
              {/* Comment 1 */}
              <Box sx={style.slideCommentMain}>
                <img alt="background" src={comment} />
                <Box sx={style.slideContent}>
                  <Typography sx={style.quoteLogo}>,,</Typography>
                  <Typography sx={style.comment}>

                  </Typography>
                  <Typography sx={style.commentorAndPosition}>
                    Barangay Niugan Staffs
                  </Typography>

                  <Avatar src={coco} alt="coco" sx={style.commentAvatar} />
                </Box>
              </Box>

              {/* Comment 2 */}
              <Box sx={style.slideCommentMain}>
                <img alt="background" src={comment} />
                <Box sx={style.slideContent}>
                  <Typography sx={style.quoteLogo}>,,</Typography>
                  <Typography sx={style.comment}>

                  </Typography>
                  <Typography sx={style.commentorAndPosition}>
                    Midwife | Cristine B. De Juaiting
                  </Typography>

                  <Avatar src={bitoy} alt="bitoy" sx={style.commentAvatar} />
                </Box>
              </Box>
            </AutoplaySlider>
          </Box>
        </Box>

        {/* Promotional Space 5 */}
        <Box sx={style.promotionalSpaceContainer}>
          <Grid container sx={style.questionContainer}>
            {/* Clinic Map */}
            <Grid item xs={12}>
            <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>
              Where to find Barangay Niugan Health Center
            </Typography>
            <Typography sx={style.promotionalTitleSub}>
              {" "}
              <br />
            </Typography>
          </Box>
              <Box sx={style.mapContainer}>
                <iframe
                  title="companyMap"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15419.360399219328!2d120.9659359!3d14.9459993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc5b73a8148bd3597!2sNiugan%20Barangay%20Hall!5e0!3m2!1sen!2sph!4v1669222294306!5m2!1sen!2sph"
                  width="10000"
                  height="600"
                  style={{ border: 0 }}
                ></iframe>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Main Body End */}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
