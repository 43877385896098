import React from 'react';

import { useFormik, Form, FormikProvider } from "formik";


// Importing Other Page Component
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Import Styling Page
import style from '../styles/Appointment';

// Import Icon
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
// Import Material UIs
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';


import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useSelector } from 'react-redux';

import { db } from '../utils/firebase';
import {

  addDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Philippines")


function Appointment({ isAuth }) {
  const user = useSelector((state) => state.user);
  const [patientData, setPatientData] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (appointment) => {
    setOpenModal(true);
    setPatientData(appointment);
  };
  const handleCloseModal = () => setOpenModal(false);

  console.log(patientData);


  const [payload, setPayload] = React.useState({
    reason: '',
    message: '',
  });

  const handleChange = (prop) => (e) => {
    setPayload({ ...payload, [prop]: e.target.value });
  };



  const formik = useFormik({
    initialValues: {
      date: '',
    }
  });

 

  const getAppointments = async () => {
    console.log('getAppointments');
    console.log(patientData.Image);

    console.log(user.currentUserData[0].UserName);

    const scheduleDate = new Date(formik.values.date);

    const auth = getAuth();
    await addDoc(collection(db, 'Appointment'), {
      FullName: user.currentUserData[0].UserName,
      UserType: "Patient",
      Date: scheduleDate,
      CreatedUser: patientData.CreatedUser,
      Created: serverTimestamp(),
      PatientUser: auth.currentUser.uid,
      Status: 'Waiting to be Confirmed',
      Reason: payload.reason,
      Message: payload.message,
    });

    await addDoc(collection(db, 'History'), {
      Title: `Patient ${user.currentUserData[0].UserName} Apply for Appointment`,
      CreatedUser: auth.currentUser.uid,
      Created: serverTimestamp(),
    });

    alert('Get Appointments Successfully');
    setOpenModal(false);
  };

  const {
    errors,
    touched,
    // values,
    resetForm,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    // getFieldMeta,
  } = formik;


  return (
    <div>
      {/* Navbar */}
      <Navbar isAuth={isAuth} />

      {/* Main Container */}
      <Box sx={style.mainContainer}>
        {/* Banner */}
        <Box sx={style.banner}></Box>

        {/* Promotional Container - Table Container */}
        <Box sx={style.promotionalSpaceContainer}>
          {/* title */}
          <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>
              Appointment Schedule List
            </Typography>
            <Typography sx={style.promotionalTitleSub}>
              {' '}
              <br />
            </Typography>
          </Box>

          {/* Table */}
          <Box sx={{ marginTop: '10px' }}>
            <TableContainer component={Paper} sx={style.tablePaperContainer}>
              <Table sx={style.doctorTable}>
                {/* Table Row - Table Head */}
                <TableHead sx={style.tableHead}>
                  <TableRow>
                    <TableCell sx={style.tableCell}>Admin's Name</TableCell>
                    <TableCell sx={style.tableCell} align="left">
                      Appointment Date
                    </TableCell>
                    <TableCell sx={style.tableCell} align="left">
                      Appointment Day
                    </TableCell>
                    <TableCell sx={style.tableCell} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Table Body */}

                {isAuth === true
                  ? user.appointments
                    .filter((appointment) => appointment.Status === 'Pending')
                    .map((appointment) => (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={style.tableCellDetails}
                            component="th"
                            scope="row"
                          >
                            {appointment.FullName}
                          </TableCell>
                          <TableCell
                            sx={style.tableCellDetails}
                            align="left"
                            component="th"
                            scope="row"
                          >
                            {format(
                              new Date(appointment.Date.seconds * 1000),
                              'PPP'
                            )}
                          </TableCell>
                          <TableCell
                            sx={style.tableCellDetails}
                            align="left"
                            component="th"
                            scope="row"
                          >
                            {format(
                              new Date(appointment.Date.seconds * 1000),
                              'EEEE'
                            )}
                          </TableCell>
                          <TableCell sx={style.tableCellDetails} align="left">
                            <Button
                              variant="contained"
                              sx={style.appointmentButton}
                              onClick={() => handleOpenModal(appointment)}
                            >
                              Get Appointment
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  : null}
              </Table>
            </TableContainer>
          </Box>
        </Box>

        {/* Modal here */}

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box sx={style.modalStyle}>
              <Box sx={style.titleModalContainer}>
                <Box>
                  <Typography sx={style.modalTitle}>
                    Make An Appointment
                  </Typography>
                </Box>

                <Box>
                  <IconButton sx={style.closeButton} onClick={handleCloseModal}>
                    <CloseIcon sx={style.closeIcon} />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={style.modalTitleDetails}>
                <Typography sx={style.modalTitle}>Patient Details</Typography>
              </Box>

              {/* Patient Details START */}
              <Box sx={style.modalDetails}>
                {/* Name */}
                <Box sx={style.labelContainerModal}>
                  <Typography sx={style.modalLabel}>Name:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <Typography sx={style.modalDetailsInfo}>
                    {user.currentUserData[0].UserName}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'none' }}>
                {/* Age */}
                <Box sx={style.labelContainerModal}>
                  <Typography sx={style.modalLabel}>Age:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <Typography sx={style.modalDetailsInfo}>
                    28 Years Old
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'none' }}>
                {/* Address */}
                <Box sx={style.labelContainerModal}>
                  <Typography sx={style.modalLabel}>Address:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <Typography sx={style.modalDetailsInfo}>
                    434 National Road, Sto. Cristo, Pulilan, Bulacan
                  </Typography>
                </Box>
              </Box>

              <Box sx={style.modalDetails}>
                {/* Contact No.: */}
                <Box sx={style.labelContainerModal}>
                  <Typography sx={style.modalLabel}>Contact No.:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <Typography sx={style.modalDetailsInfo}>
                    {user.currentUserData[0].ContactNumber}
                  </Typography>
                </Box>
              </Box>
              {/* Patient Details END */}

              {/* Appointment Details START */}
              <Box sx={style.modalTitleDetails}>
                <Typography sx={style.modalTitle}>
                  Appointment Details
                </Typography>
              </Box>

              <Box sx={style.modalDetails}>
                {/* Admin's Name */}
                <Box sx={style.labelContainerModal}>
                  <Typography sx={style.modalLabel}>Admin's Name:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <Typography sx={style.modalDetailsInfo}>
                    {patientData.FullName}
                  </Typography>
                </Box>
              </Box>

              <Box sx={style.modalDetails}>
                {/* Reason */}
                <Box
                  sx={{ display: 'flex', alignItems: 'center', width: '30%' }}
                >
                  <Typography sx={style.modalLabel}>Services:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <FormControl fullWidth sx={{ border: '1px solid #8e8e8e' }}>
                    <Select
                      sx={style.selectReason}
                      displayEmpty
                      value={payload.reason}
                      onChange={handleChange('reason')}
                    >
                      <MenuItem
                        value={' Regular Check Up'}
                        sx={style.selectChoices}
                      >
                        Regular Check Up
                      </MenuItem>
                      <MenuItem
                        value={'Family Planning'}
                        sx={style.selectChoices}
                      >
                        Family Planning
                      </MenuItem>
                      <MenuItem
                        value={'Prenatal Check-up'}
                        sx={style.selectChoices}
                      >
                        Prenatal Check-up
                      </MenuItem>
                      <MenuItem value={'Immunization'} sx={style.selectChoices}>
                        Immunization
                      </MenuItem>
                      <MenuItem value={'Availment of Medecine'} sx={style.selectChoices}>
                        AvailMent of Medecine
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box sx={style.modalDetails}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', width: '30%' }}
                >
                  <Typography sx={style.modalLabel}>Date & Time:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      name="date"
                      format="MM/dd/yyy"
                      {...getFieldProps("date")}
                      onChange={(value) => setFieldValue("date", value)}
                      error={Boolean(
                        touched.date && errors.date
                      )}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                      // disableTimes = { [1, 2, 3, 4, 5, 6, 7, 8]}
                      minDate={dayjs()}
                    //  shouldDisableDate={isWeekday(formik.values.date)}



                    />
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box sx={style.modalDetails}>
                {/* Messsage */}
                <Box
                  sx={{ display: 'flex', alignItems: 'center', width: '30%' }}
                >
                  <Typography sx={style.modalLabel}>Message:</Typography>
                </Box>
                <Box sx={style.infoContainerModal}>
                  <TextField
                    multiline
                    rows={5}
                    placeholder="How do you feel?"
                    sx={style.textFieldModal}
                    value={payload.message}
                    onChange={handleChange('message')}
                  />
                </Box>
              </Box>

              <Button
                variant="contained"
                sx={style.appointmentButtonModal}
                onClick={getAppointments}
              >
                Get Appointment
              </Button>

              {/* Appointment Details END */}
            </Box>
          </Fade>
        </Modal>
      </Box>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Appointment;
