import React, { useState } from "react";

import style from "../styles/ProfileInfo";

import {
  Box,
  Paper,
  Typography,
  TextField,
  Container,
  Snackbar,
  CircularProgress,
  Grid,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
// icon
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDropzone } from "react-dropzone";


//backend
import { auth, db, storage } from "../utils/firebase";

import {
  doc,
  updateDoc,
  arrayUnion,
  setDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  mt: 20,
};

const thumb = {
  display: "inline-flex",
  border: "1px dashed gray",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  borderRadius: 100,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: 100,
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const imgEmpty = {
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  width: "30%",
  height: "100%",
};
const thumbInnerEmpty = {
  display: "block",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: 50,
};

export default function ProfileInfo() {

  const const_term = 1024;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [avatarError, setAvatarError] = useState(false)
  const user = useSelector((state) => state.user);

  const [payload, setPayload] = React.useState({
    fullname: user.currentUserData[0].UserName,
    contact: user.currentUserData[0].ContactNumber,
    profilePic: null,
    imagePath: ""
  });

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    maxSize: 3100000,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      // setFieldValue("avatar", "gago");
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const remove = (file) => () => {
    console.log("removeFile...");
    getRootProps.onDrop.acceptedFiles.splice(
      getRootProps.onDrop.acceptedFiles.indexOf(file),
      1
    );
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="hahah" />
        <DeleteIcon onClick={remove} />
      </div>
    </div>
  ));

  const thumbsEmpty = (
    <div style={thumb}>
      <div style={thumbInnerEmpty}>
        <AddAPhotoRoundedIcon style={imgEmpty} />
        {/* <Typography fontSize={10} color="textPrimary">Upload photo</Typography> */}
      </div>
    </div>
  );

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <Box sx={{ my: 2, border: 1, borderRadius: 2, p: 2, color: "red" }}>
      <Typography key={file.path} sx={{ fontSize: 12, fontWeight: 700 }}>
        {file.path} - {(file.size / const_term ** 2).toFixed(3)} Mb
      </Typography>
      {errors.map((e) => (
        <Typography key={e.code} sx={{ fontSize: 12 }}>
          {" "}
          {e.message}
        </Typography>
      ))}
    </Box>
  ));

  const handleChangeUser = (prop) => (e) => {
    setPayload({ ...payload, [prop]: e.target.value });
  };

  const updateProfile = async () => {
    if (payload.fullname === user.currentUserData[0].UserName && payload.contact === user.currentUserData[0].ContactNumber && files.length === 0) {
      if (window.confirm('No Change Done')) {
        alert("OK")
        window.location.reload(false)
      }
    }
    else if (payload.contact.length < 10 || payload.contact.length > 10) {
      alert('Please enter 10 digit phone number');
    }
    else {
      if (files.length > 0) {
        setAvatarError(false);
        setLoading(true)
        const profileImgsRef = ref(storage, `/Users/${auth.currentUser.uid}/${files[0].path}`);
        const uploadTask = uploadBytesResumable(profileImgsRef, files[0], "data_url");
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
              case 'paused':
                break;
              case 'running':
                break;
              default:
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;
              // ...
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
              default:
            }
            setLoading(false)
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
              const user_prof = doc(db, "Users", auth.currentUser.uid);
              await updateDoc(doc(db, "Users", auth.currentUser.uid), {
                Image: arrayUnion(downloadURL),
                ImageName: files[0].path,
              }).then(async () => {
                await setDoc(
                  user_prof,
                  {
                    Image: downloadURL,
                    ProfilePicName: files[0].path,
                  },
                  { merge: true }
                ).then(async () => {
                  const userId = auth.currentUser.uid;
                  await setDoc(
                    doc(db, "Appointment", userId),
                    {
                      Image: downloadURL,
                    },
                    { merge: true }
                  )
                })
              })
            })
          }
        )
      }
    }
    if (window.confirm('Are you sure you want to save changes?')) {
      const user_prof = doc(db, "Users", auth.currentUser.uid);
      setLoading(true);
      await setDoc(
        user_prof,
        {
          UserName: payload.fullname,
          ContactNumber: Number('63' + payload.contact),
        },
        { merge: true }
      ).then(async () => {
        const userId = auth.currentUser.uid;
        await setDoc(
          doc(db, "Appointment", userId),
          {
            ClientName: payload.fullname,
            ContactNumber: Number('63' + payload.contact),
            uid: userId,
            appointmentReq: false,
          },
          { merge: true }
        ).then(() => {
          setPayload({})
          setSnackbarOpen(true);
          setFiles([]);
          setLoading(false);
        })
      })
    }
  }

  return (
    <Box>
      <Box sx={style.headerBox}>
        <Typography sx={style.headerText}>My Profile Information</Typography>
      </Box>
      <Container sx={{ mt: 3, mb: 5 }}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Profile Update Successfully
          </Alert>
        </Snackbar>

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Item>
                  <Box sx={{ p: 1 }}>
                    <Box {...getRootProps({ className: "dropzone" })}>
                      <input
                        // {...getFieldMeta("avatar")}
                        {...getInputProps()}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box style={thumbsContainer}>
                          <Box sx={{ m: 1, position: "relative" }}>
                            {thumbs.length !== 0 ? thumbs : thumbsEmpty}

                            {loading && (
                              <CircularProgress
                                size={110}
                                sx={{
                                  color: "primary",
                                  position: "absolute",
                                  top: -6,
                                  left: -6,
                                  zIndex: 1,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Typography sx={style.imageTypography}>
                          Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                        </Typography>
                      </Box>
                    </Box>
                    {fileRejectionItems}
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={12} md={8}>
                <Item>
                  <Box
                    display="grid"
                    // gridTemplateColumns="repeat(12, 1fr)"
                    sx={style.boxGrid}
                    gap={2}
                  >
                    <Box gridColumn="span 6">
                      <TextField
                        sx={style.textField}
                        onChange={handleChangeUser('fullname')}
                        placeholder="Full name"
                        fullWidth
                      />
                    </Box>
                    <Box gridColumn="span 6">
                      <TextField
                        sx={style.textField}
                        onChange={handleChangeUser('contact')}
                        placeholder="Phone number"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      my: 2,
                    }}
                  >
                    <LoadingButton
                      style={{
                        textTransform: "Capitalize",
                        borderRadius: 8,
                      }}
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                      onClick={updateProfile}
                      loading={loading}
                    >
                      Update Profile
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}
