import React from 'react'

// Import Styling Page
import style from '../styles/ProfileRecords'

// Import Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useSelector } from 'react-redux';
// Import Material UIs
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
} from '@mui/material';
import { format } from "date-fns";
import ModalViewRecord from '../components/ModalViewRecord';

function ProfileRecords() {
const user = useSelector((state) => state.user);
const [open, setOpen] = React.useState(false);
const [recordId, setRecordId] = React.useState("");

const handleOpen = (id) => {
    setOpen(!open);
    setRecordId(id)
};
    return (
        <div>
            <ModalViewRecord recordId={recordId} open={open} handleOpen={handleOpen} />
            <Box sx={style.headerBox}>
                <Typography sx={style.headerText}>My Check-up Records</Typography>
                <Typography sx={style.headerSubText}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</Typography>
            </Box>

   
{/*         
            <Box sx={style.emptyNotice}>
                <Typography sx={style.textNotice}>No Appointment Yet.</Typography>
            </Box>

      
            <Box sx={style.deleteNotice}>
                <Typography sx={style.textNotice}>Appointment Successfully Cancelled.</Typography>
            </Box>

          
            <Box sx={style.mainSearchContainer}>
                <Box sx={style.searchContainer}>
                    <Typography sx={style.textLabel}>Search:</Typography>
                    <TextField sx={style.searchTxt} />
                </Box>
            </Box> */}

            {/* All Table Container */}
            <Box sx={style.tableContainer}>
                <TableContainer component={Paper} sx={style.tablePaperContainer}>
                    <Table sx={style.doctorTable}>

                        {/* Table Row - Table Head */}
                        <TableHead sx={style.tableHead}>
                            <TableRow>
                                <TableCell sx={style.tableCell}>Record ID.</TableCell>
                                <TableCell sx={style.tableCell}>Doctor's Name</TableCell>
                                <TableCell sx={style.tableCell} align="left">Category</TableCell>
                                <TableCell sx={style.tableCell} align="left">Date</TableCell>
                                {/* <TableCell sx={style.tableCell} align="left">Appointment Day</TableCell> */}
                                <TableCell sx={style.tableCell} align="center">View</TableCell>
                            </TableRow>
                        </TableHead>

                        {/* Table Content */}
                        { user.patients.filter((item ) => item.PatientUid === user.currentUserData[0].UserUid).map((item) => (
                        <TableBody>
                            <TableRow>
                                <TableCell sx={style.tableCellDetails} component="th" scope="row">{item.id}</TableCell>
                                <TableCell sx={style.tableCellDetails} component="th" scope="row">{user.users.filter((user ) => user.UserUid === item.CreatedUser).map((item) => (item.UserName))}</TableCell>
                                <TableCell sx={style.tableCellDetails} component="th" scope="row">{item.Category}</TableCell>
                                <TableCell sx={style.tableCellDetails} component="th" scope="row">  {format(new Date((item.Created.seconds*1000)), 'PPP')}</TableCell>
                                {/* <TableCell sx={style.tableCellDetails} component="th" scope="row">Friday</TableCell> */}
                                <TableCell sx={style.tableCellDetails} component="th" scope="row">
                                    <Button sx={style.viewButton} onClick={() => handleOpen (item.id)}>
                                        <VisibilityIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                        ))}

                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}

export default ProfileRecords