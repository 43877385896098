import * as React from 'react'
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// Importing Other Page Component
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProfileAppointment from '../pages/ProfileAppointment';
import ProfileRecords from '../pages/ProfileRecords'

// Import Styling Page
import style from '../styles/Profile'

// Import Material UIs
import {
    Box,
    Typography,
    Tabs,
    Tab,
    Grid,
} from '@mui/material';

//backend
import { getAuth } from "firebase/auth";
import ProfileInfo from './ProfileInfo';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Profile({isAuth}) {
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    // handle 
    const handleLogout = () => {
        const auth = getAuth();
        auth.signOut();
        history.push("/");
      }

    return (
        <div>
            {/* Navbar */}
            <Navbar isAuth={isAuth}/>

            {/* Main Container - START*/}
            <Box sx={style.mainContainer}>

                {/* Banner */}
                <Box sx={style.banner}>
                </Box>

                {/* Tabs with Grid Here */}
                <Grid container>

                    {/* Grid Tabs Options*/}
                    <Grid item xs={12} md={2.5}>
                        <Box>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                sx={style.tabsStyle}
                            >
                                {/* <Tab label="Dashboard" {...a11yProps(0)} sx={style.tabLabel} /> */}
                                 <Tab label="Profile" {...a11yProps(0)} sx={style.tabLabel} /> 
                                <Tab label="Appointment" {...a11yProps(1)} sx={style.tabLabel} />
                                <Tab label="Logout" {...a11yProps(4)} sx={style.tabLabel} onClick={handleLogout} />
                            </Tabs>
                        </Box>
                    </Grid>

                    {/* Grid Tabs Panel */}
                    <Grid item xs={12} md={9.5}>
                        <Box>
                            {/* Dashboard */}
                            {/* <TabPanel value={value} index={0}>
                                First Dashboard
                            </TabPanel> */}
                            {/* <TabPanel value={value} index={1}>
                                <ProfileAppointment />
                            </TabPanel> */}
                             <TabPanel value={value} index={0}>
                            <ProfileInfo />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                            <ProfileAppointment />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                               <ProfileRecords />
                            </TabPanel>

                            {/* <TabPanel value={value} index={4}>
                                Item Five
                            </TabPanel> */}
                        </Box>
                    </Grid>
                </Grid>


            </Box>
            {/* Main Container - END*/}

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default Profile