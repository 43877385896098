const style = {
    mainContainer: {
        width: {
            xs: '95%',
            md: '80%',
        },

        margin: 'auto',
        marginBottom: '10px',
    },

    banner: {
        width: '100%',
        height: '2vh',
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            xs: '10px',
            md: '100px',
        },
    },

    promotionalSpaceContainer: {
        marginTop: '50px',
    },

    titleContainer: {
        textAlign: 'center',
        marginBottom: '10px',
        marginTop: '50px',
    },

    promotionalTitle: {
        fontFamily: 'semibold',
        fontSize: '18px',
        position: 'relative',
        marginBottom: '3rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#479923',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
        },
    },

    promotionalTitleSub: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '10px 0px 20px 0px',
        lineHeight: '25px',
    },

    doctorTable: {
        minWidth: 650,
        // backgroundColor: '#16304D',
        backgroundColor: '#fff',
        borderRadius: 0,
    },

    tableCell: {
        fontFamily: 'semibold',
        fontSize: '13px',
        color: '#fff',
    },

    tablePaperContainer: {
        boxShadow: 0,
        borderRadius: 0,
    },

    tableHead: {
        backgroundColor: '#16304D',
    },

    tableCellDetails: {
        fontFamily: 'poppins',
        fontSize: '13px',
        color: '#000',
    },

    appointmentButton: {
        fontFamily: 'poppins',
        fontSize: '11px',
        textTransform: 'capitalize',
        padding: '5px 0px 5px 0px',
        backgroundColor: '#16304D',
        width: '100%',

        '&:hover': {
            backgroundColor: '#0e2136',
        },
    },

    appointmentButtonModal: {
        my:2,
        fontFamily: 'poppins',
        fontSize: '11px',
        textTransform: 'capitalize',
        padding: '5px 0px 5px 0px',
        backgroundColor: '#16304D',
        width: '100%',

        '&:hover': {
            backgroundColor: '#0e2136',
        },
    },

    mainSearchContainer: {

    },

    searchContainer: {
        width: {
            md: '30%',
        },
        display: 'flex',
        alignItems: 'center',
    },

    textLabel: {
        fontFamily: 'poppins',
        fontSize: '14px',
        marginRight: '10px',
    },

    searchTxt: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#cccccc',
            },
            '&:hover fieldset': {
                borderColor: '#479923',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #479923',
            },

            fontFamily: 'Poppins',
            fontSize: '12px',
            padding: '10px',
            height: '45px',
            borderRadius: 0,
        },

        width: '100%',
        backgroundColor: '#fff',

        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
            backgroundColor: '#fff',
        },
    },

    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            md: '30%',
        },
        bgcolor: '#fff',
        boxShadow: 24,
        p: 2,
    },

    titleModalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },

    modalTitle: {
        fontFamily: 'semibold',
        fontSize: '15px',
    },

    closeButton: {
        backgroundColor: '#800101',
        padding: '2px',
        borderRadius: 0,

        '&:hover': {
            backgroundColor: '#a10e0e',
        },
    },

    closeIcon: {
        fontSize: '15px',
    },

    modalLabel: {
        fontFamily: 'semibold',
        fontSize: '14px',
    },

    modalDetails: {
        display: 'flex',
        width: '100%',
        marginBottom: '5px',
    },

    labelContainerModal: {
        width: '30%',
    },

    infoContainerModal: {
        width: '70%',
    },

    modalDetailsInfo: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    modalTitleDetails: {
        textAlign: 'center',
        margin: '20px 20px',
    },

    selectReason: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#383838',
        height: '35px',
        boxShadow: 0,

        "& .MuiSvgIcon-root": {
            color: '#000',
        },
    },

    selectChoices: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    textFieldModal: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#8e8e8e',
            },
            '&:hover fieldset': {
                borderColor: '#8e8e8e',
            },
            '&.Mui-focused fieldset': {
                border: '2px solid #8e8e8e',
            },

            fontFamily: 'Poppins',
            fontSize: '13px',
            padding: '5px',
            borderRadius: 0,
        },

        width: '100%',


        '& .MuiInputBase-input': {
            color: '#000',
            padding: '5px',
            height: '30px',
        },
    },
}
export default style;