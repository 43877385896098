const style = {
    mainContainer: {
        padding: '10px 0px 10px 0px',
        margin: '150px 0px 0px 0px',
    },

    promotionalTitle: {
        fontFamily: 'semibold',
        fontSize: '18px',
        position: 'relative',
        marginBottom: '3rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#479923',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
        },
    },

    promotionalTitleSub: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '10px 0px 20px 0px',
        lineHeight: '25px',
    },

    titleContainer: {
        textAlign: 'center',
    },

    contactMainContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },

    iconMui: {
        fontSize: '35px',
    },

    contactTitle: {
        fontFamily: 'semibold',
    },

    contactInfo: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    perItem: {
        width: {
            xs: '100%',
            md: '20%',
        },
    },

    contactContainer: {
        margin: '100px 0px 100px 0px',
    },
}
export default style;