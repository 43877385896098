import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
//import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyCCjnTov5i3ez53noLpy_PXOYZGnoQxwl4",
  authDomain: "finalhecsemis.firebaseapp.com",
  projectId: "finalhecsemis",
  storageBucket: "finalhecsemis.appspot.com",
  messagingSenderId: "660148965861",
  appId: "1:660148965861:web:62b3a4727bc471a0dcdf0c",
  measurementId: "G-0R4T5P2LFC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
