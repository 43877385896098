import React from 'react'

// Importing Other Page Component
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// Import Styling Page
import style from '../styles/About'
import { useHistory } from "react-router-dom";

// Import Image
import nurse from '../image/nurse.jpg'

// Import Icons
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';


// Import Material UIs
import {
    Box,
    Typography,
    Button,
    Grid,
} from '@mui/material';


function About({isAuth}) {

    const history = useHistory();

    return (
        <div>
            {/* Navbar */}
            <Navbar isAuth={isAuth}/>

            {/* Main Container */}
            <Box sx={style.mainContainer}>

                {/* Banner */}
                <Box sx={style.banner}>
                </Box>

                {/* Promotional Space 1 */}
                <Box sx={style.promotionalSpaceContainer}>
                    {/* title */}
                    <Box sx={style.titleContainer}>
                        <Typography sx={style.promotionalTitle}>All You Need To Know About Us</Typography>
                        <Typography sx={style.promotionalTitleSub}></Typography>
                    </Box>

                    <Grid container>
                        {/* Promotional Picture */}
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={style.gridPic}>
                            <Box>
                                <img
                                    alt="nurse"
                                    src={nurse}
                                    style={{
                                        width: '400px',
                                        height: '400px',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Promotional Text */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box sx={style.promotionalTextContainer}>
                                <Typography sx={style.promotionalTextBig}></Typography>

                                <Typography sx={style.promotionalTextSmall}>
                                   Keeping Barangay records is a one way to easily track resident’s information. HECSEMIS is capable in recording this bulk information and can be stored in a long period of time. This system can meet strategic objectives for reducing paper consumption and reducing time consuming file retrieval from bulky documents. HESCEMIS can track residents record such as personal to family information,and can create daily reports for the Barangay.
                                </Typography>

                                <Typography sx={style.promotionalTextSmall}>
                                   
                                </Typography>

                                <Button
                                onClick={()=>history.push("/contact")}
                                 variant='contained' sx={style.mauButton}
                                >Contact Us for More!</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                
                {/* Promotional Space 2 */}
        <Box sx={style.promotionalSpaceContainer}>
                    {/* title */}
                    <Box sx={style.titleContainer}>
                        <Typography sx={style.promotionalTitle}>Services We Offer</Typography>
                        <Typography sx={style.promotionalTitleSub}> <br /> </Typography>
                    </Box>

                    <Grid container sx={style.serviceGridContainer}>
                        {/* Column 1 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <LocalHospitalIcon sx={style.checkIcon} />
                                <Typography sx={style.serviceTitle}>Check-ups</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>

                        {/* Column 2 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <PregnantWomanIcon sx={style.pregnantIcon} />
                                <Typography sx={style.serviceTitle}>Prenatal Check-ups</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>

                        {/* Column 3 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <VaccinesIcon sx={style.medicineIcon} />
                                <Typography sx={style.serviceTitle}>Immunization</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>

                        {/* Column 4 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.serviceBox}>
                                <FamilyRestroomIcon sx={style.familyIcon} />
                                <Typography sx={style.serviceTitle}>Family Planning</Typography>
                                <Typography sx={style.serviceText}></Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                {/* Promotional Space 3 */}
                <Box sx={style.promotionalSpaceContainer}>
                    <Box>
                        <Typography sx={style.dotDivider}>• • • • • •</Typography>
                        <Typography sx={style.promotionalTextSmall}>
                          
                        </Typography>
                    </Box>
                </Box>


            </Box>

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default About