const style = {
    promotionalSpaceContainer: {
        margin: '50px 50px 50px 50px',
    },

    promotionalTitle: {
        fontFamily: 'semibold',
        fontSize: '18px',
        position: 'relative',
        marginBottom: '3rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#479923',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
        },
    },

    promotionalTitleSub: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '10px 0px 20px 0px',
        lineHeight: '25px',
    },

    mainContainer: {
        width: {
            xs: '95%',
            md: '85%',
        },

        margin: 'auto',
        marginTop: '150px',
    },

    titleContainer: {
        textAlign: 'center',
    },

    contentContainer: {
        textAlign: 'center',
    },

    contentText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
    },

    imageContainer: {
        width: '180px',
        marginBottom: '10px',
        transition: 'all ease 0.5s',
        filter: 'saturate(110%)',

        '&:hover': {
            filter: 'grayscale(100%)',
        }
    },

    profileName: {
        fontFamily: 'Semibold',
        fontSize: '14px',
    },

    profilePosition: {
        fontFamily: 'Poppins',
        fontSize: '13px',
        color: '#6e6e6e',
    },

    mainProfileDev: {
        justifyContent: 'center',
        textAlign: 'center',
    },
}
export default style;