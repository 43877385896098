import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";


// Importing Other Page Component
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Styling Page
import style from "../styles/Doctor";



// Import Material UIs
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import DatePicker from "@mui/lab/DatePicker";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";



//redux
import { useSelector } from "react-redux";

//backend
import { db } from "../utils/firebase";
import {
  addDoc,
  serverTimestamp,
  collection,
  updateDoc,
  doc,
  increment,
} from "firebase/firestore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


function Forms({ isAuth }) {
  const user = useSelector((state) => state.user);
  const [, setDoctor] = useState([]);

  useEffect(() => {
    user.users.filter((user) =>
      user.UserType === "Doctor" ? setDoctor([user]) : []
    );
  }, [user.users]);


  //yup and formik

  const phoneFormat =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const [schemaType, setSchemaType] = useState("");

  const TreatmentSchema = Yup.object().shape({
    firstNamef: Yup.string().required('First Name is required'),
    middleNamef: Yup.string(),
    lastNamef: Yup.string().required('Last Name is required'),
    // gender: Yup.string().required("Gender is required"),
    residentAddressTreatment: Yup.string().required(
      "Resident Address is required"
    ),
    age: Yup.string().required("Age is required"),
    dateConstutation: Yup.string().required("Date Constutation is required"),
    bloodPressure: Yup.string().required("Blood Pressure is required"),
    temperature: Yup.string().required("Temperature is required"),
    weight: Yup.string().required("Weight is required"),
    purpose: Yup.string().required(" Purpose of Visit is required"),
    diagnosis: Yup.string().required("Diagnosis is required"),
    medicationTreatment: Yup.string().required(
      "Medication Treatment is required"
    ),
    laboratoryFinding: Yup.string().required("Laboratory Finding is required"),
  });

  const LoginSchema = Yup.object().shape({
    contactNumber: Yup.string()
      .matches(phoneFormat, "Contact number is invalid")
      .min(10, "Phone number is invalid")
      .max(10, "Phone number is invalid")
      .required("Phone number is required"),

    // role: Yup.string().required("Role is required"),

    firstName: Yup.string().required('First Name is required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    gender: Yup.string().required("Gender is required"),
    birthDate: Yup.string().required("Birth Date is required"),
    birthPlace: Yup.string().required("Birthplace is required"),
    bloodType: Yup.string().required("Blood Type is required"),
    civilStatus: Yup.string().required("Civil Status is required"),
    residentAddress: Yup.string().required("Resident Address is required"),
    familyMember: Yup.string().required("Family Member is required"),
    educationalAttainment: Yup.string().required(
      "Educational Attainment is required"
    ),
    fourpsMember: Yup.string().required("4PS Member is required"),
    houseNumber: Yup.string().required("House Hold Number is required"),
  });

  const PrenatalSchema = Yup.object().shape({
    firstNamep: Yup.string().required('First Name is required'),
    middleNamep: Yup.string(),
    lastNamep: Yup.string().required('Last Name is required'),
    // gender: Yup.string().required("Gender is required"),
    residentAddressPrenatal: Yup.string().required(
      "Resident Address is required"
    ),
    agePrenatal: Yup.string().required("Age is required"),
    contactNumberPrenatal: Yup.string()
      .matches(phoneFormat, "Contact number is invalid")
      .min(10, "Phone number is invalid")
      .max(10, "Phone number is invalid")
      .required("Phone number is required"),

    gravidity: Yup.string().required("Gravidity is required"),
    parity: Yup.string().required("Parity is required"),
    term: Yup.string().required("Term is required"),
    preterm: Yup.string().required("Preterm is required"),
    liveBirth: Yup.string().required(" Live Birth is required"),
    abortion: Yup.string().required("Abortion is required"),
    syphilis: Yup.string().required("Syphilis is required"),

    lmp: Yup.string().required("LMP is required"),
    edc: Yup.string().required("EDC is required"),
    aog: Yup.string().required("AOG is required"),
    ti: Yup.string().required("TI is required"),
    iron: Yup.string().required("Iron is required"),
    others: Yup.string().required("Others is required"),
    penicillin: Yup.string().required("Penicillin is required"),

    scheduleNextVisit: Yup.string().required("Schedule Next Visit is required"),
    fundicHeight: Yup.string().required("Fundic Height is required"),
    fatalHeartTone: Yup.string().required("Fatal Heart Tone is required"),
  });

  const ImmunizationSchema = Yup.object().shape({
    firstNamei: Yup.string().required('First Name is required'),
    middleNamei: Yup.string(),
    lastNamei: Yup.string().required('Last Name is required'),
    // gender: Yup.string().required("Gender is required"),
    residentAddressImmunization: Yup.string().required(
      "Resident Address is required"
    ),
    ageImmunization: Yup.string().required("Age is required"),
    contactNumberImmunization: Yup.string()
      .matches(phoneFormat, "Contact number is invalid")
      .min(10, "Phone number is invalid")
      .max(10, "Phone number is invalid")
      .required("Phone number is required"),

    gradeImmunization: Yup.string().required("Grade is required"),
    schoolImmunization: Yup.string().required("School is required"),
    dateBirthImmunization: Yup.string().required("Date of Birth is required"),

    allergiesImmunization: Yup.string().required("Allergies is required"),
    healthConditionsImmunization: Yup.string().required(
      " Health Condition is required"
    ),
    chickenPoxImmunization: Yup.string().required("Chicken Pox is required"),
    checkenPoxVaccineImmunization: Yup.string().required(
      "Chicken Pox Vaccine is required"
    ),

    pregnantImmunization: Yup.string().required("Pregnant required"),
    consentImmunization: Yup.string().required("Consent required"),
    relationshipImmunization: Yup.string().required("Relationship is required"),
    workImmunization: Yup.string().required("Work is required"),
  });

  const MedicineSchema = Yup.object().shape({
    // role: Yup.string().required("Role is required"),

    firstNamem: Yup.string().required('First Name is required'),
    middleNamem: Yup.string(),
    lastNamem: Yup.string().required('Last Name is required'),
    medicineName: Yup.string().required("Medicine is required"),
    medicineQuantity: Yup.string().required("Quantity is required"),
    residentAddressMedicine: Yup.string().required("Resident Address is required"),
  });

  const validationSchemaFn = () => {
    if (schemaType === "Resident") {
      return LoginSchema;
    }
    if (schemaType === "Treatment") {
      return TreatmentSchema;
    }
    if (schemaType === "Prenatal") {
      return PrenatalSchema;
    }
    if (schemaType === "Immunization") {
      return ImmunizationSchema;
    }
    if (schemaType === "Medicine") {
      return MedicineSchema;
    }
  };

  const formik = useFormik({
    initialValues: {
      // Resident Information
      firstName: "",
      middleName: "",
      lastName: "",
      // fullNameRegisteredOnline: "",
      gender: "",
      birthDate: "",
      birthPlace: "",
      bloodType: "",
      civilStatus: "",
      spouseName: "",
      residentAddress: "",
      contactNumber: "",
      familyMember: "",
      educationalAttainment: "",
      fourpsMember: "",
      houseNumber: "",
      // Individual Treatment Record
      firstNamef: '',
      middleNamef: '',
      lastNamef: '',
      residentAddressTreatment: "",
      age: "",
      dateConstutation: "",
      bloodPressure: "",
      temperature: "",
      weight: "",
      purpose: "",
      diagnosis: "",
      medicationTreatment: "",
      laboratoryFinding: "",

      refferedFrom: "",
      refferedTo: "",
      reasonRefferal: "",
      refferedBy: "",
      chiefComplaint: "",

      // Prenatal Form
      firstNamep: '',
      middleNamep: '',
      lastNamep: '',
      residentAddressPrenatal: "",
      agePrenatal: "",
      contactNumberPrenatal: "",
      gravidity: "",
      parity: "",
      term: "",
      preterm: "",
      liveBirth: "",
      abortion: "",
      syphilis: "",
      lmp: "",
      edc: "",
      aog: "",
      ti: "",
      iron: "",
      others: "",
      penicillin: "",
      scheduleNextVisit: "",
      fundicHeight: "",
      fatalHeartTone: "",

      // Immunization
      firstNamei: '',
      middleNamei: '',
      lastNamei: '',
      residentAddressImmunization: "",
      ageImmunization: "",
      gradeImmunization: "",
      schoolImmunization: "",
      dateBirthImmunization: "",
      allergiesImmunization: "",
      healthConditionsImmunization: "",
      chickenPoxImmunization: "",
      checkenPoxVaccineImmunization: "",
      reactionVaccineImmunization: "",
      pregnantImmunization: "",
      consentImmunization: "",
      relationshipImmunization: "",
      contactNumberImmunization: "",
      workImmunization: "",

      // Availment of medicine
      firstNamem: '',
      middleNamem: '',
      lastNamem: '',
      medicineName: "",
      medicineQuantity: "",
      residentAddressMedicine: "",
    },

    validationSchema: validationSchemaFn(),
    onSubmit: async () => {
      // console.log(formik.values.email);


      if (schemaType === "Resident") {
        await addDoc(collection(db, "Patients"), {
          FullName:
            formik.values.firstName +
            ' ' +
            formik.values.middleName +
            ' ' +
            formik.values.lastName,
          Gender: formik.values.gender,
          BirthDate: formik.values.birthDate.toString(),
          BirthPlace: formik.values.birthPlace,
          BloodType: formik.values.bloodType,
          CivilStatus: formik.values.civilStatus,
          SpouseName: formik.values.spouseName,
          ResidentAddress: formik.values.residentAddress,
          ContactNumber: formik.values.contactNumber,
          FamilyMember: formik.values.familyMember,
          EducationalAttainment: formik.values.educationalAttainment,
          FourpsMember: formik.values.fourpsMember,
          HouseNumber: formik.values.houseNumber,
          Category: "Resident Information",
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        await addDoc(collection(db, "History"), {
          Title: `Created Patient ${formik.values.fullName} Resident Record`,
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });
        resetForm({
          values: {
            fullName: "",
            gender: "",
            birthDate: "",
            birthPlace: "",
            bloodType: "",
            civilStatus: "",
            spouseName: "",
            residentAddress: "",
            contactNumber: "",
            familyMember: "",
            educationalAttainment: "",
            fourpsMember: "",
            houseNumber: "",
          },
        });
        setSubmitting(false);
        window.location.reload();
      } else if (schemaType === "Treatment") {
        await addDoc(collection(db, "Patients"), {
          FullName:
            formik.values.firstNamef +
            ' ' +
            formik.values.middleNamef +
            ' ' +
            formik.values.lastNamef,
          ResidentAddress: formik.values.residentAddressTreatment,
          Age: Number(formik.values.age),
          ConstutationDate: formik.values.dateConstutation.toString(),
          BloodPressure: formik.values.bloodPressure,
          Temperature: formik.values.temperature,
          Weight: formik.values.weight,
          PurposeVisit: formik.values.purpose,
          Diagnosis: formik.values.diagnosis,
          MedicationTreatment: formik.values.medicationTreatment,
          LaboratoryFinding: formik.values.laboratoryFinding,

          RefferedFrom: formik.values.refferedFrom,
          RefferedTo: formik.values.refferedTo,
          ReasonRefferal: formik.values.reasonRefferal,
          RefferedBy: formik.values.refferedBy,
          ChiefComplaint: formik.values.chiefComplaint,

          PatientUid: "Online Form",
          Category: "Treatment Record",
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        await addDoc(collection(db, "History"), {
          Title: `Created Patient ${formik.values.fullNameTreatment} Treatment Record`,
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });
        resetForm({
          values: {
            fullNameTreatment: "",
            residentAddressTreatment: "",
            age: "",
            dateConstutation: "",
            bloodPressure: "",
            temperature: "",
            weight: "",
            purpose: "",
            diagnosis: "",
            medicationTreatment: "",
            laboratoryFinding: "",
            refferedFrom: "",
            refferedTo: "",
            reasonRefferal: "",
            refferedBy: "",
            chiefComplaint: "",
          },
        });
        setSubmitting(false);
        window.location.reload();
      } else if (schemaType === "Prenatal") {
        await addDoc(collection(db, "Patients"), {
          FullName:
            formik.values.firstNamep +
            ' ' +
            formik.values.middleNamep +
            ' ' +
            formik.values.lastNamep,
          ResidentAddress: formik.values.residentAddressPrenatal,
          Age: Number(formik.values.agePrenatal),
          ContactNumber: Number(formik.values.contactNumberPrenatal),

          Gravidity: formik.values.gravidity,
          Parity: formik.values.parity,
          Term: formik.values.term,
          Preterm: formik.values.preterm,
          LiveBirth: formik.values.liveBirth,
          Abortion: formik.values.abortion,
          Syphilis: formik.values.syphilis,

          LMP: formik.values.lmp,
          EDC: formik.values.edc,
          AOG: formik.values.aog,
          TI: formik.values.ti,
          Iron: formik.values.iron,
          Others: formik.values.others,
          Penicillin: formik.values.penicillin,

          ScheduleNextVisit: formik.values.scheduleNextVisit.toString(),
          FundicHeight: formik.values.fundicHeight,
          FatalHeartTone: formik.values.fatalHeartTone,

          PatientUid: user.users
            .filter((item) => item.UserName === formik.values.fullNamePrenatal)
            .map((item) => item.id)
            .toString(),
          Category: "Prenatal Record",
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        await addDoc(collection(db, "History"), {
          Title: `Created Patient ${formik.values.fullNamePrenatal} Prenatal Record`,
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });
      } else if (schemaType === "Immunization") {
        await addDoc(collection(db, "Patients"), {
          FullName:
            formik.values.firstNamei +
            ' ' +
            formik.values.middleNamei +
            ' ' +
            formik.values.lastNamei,
          ResidentAddress: formik.values.residentAddressImmunization,
          Age: Number(formik.values.ageImmunization),
          ContactNumber: Number(formik.values.contactNumberImmunization),

          Grade: formik.values.gradeImmunization,
          School: formik.values.schoolImmunization,
          DateBirth: formik.values.dateBirthImmunization.toString(),

          Allergies: formik.values.allergiesImmunization,
          HealthCondition: formik.values.healthConditionsImmunization,
          ChickenPox: formik.values.chickenPoxImmunization,
          ChickenPoxVaccine: formik.values.checkenPoxVaccineImmunization,
          ReactionChickenPoxVaccine: formik.values.reactionVaccineImmunization,
          Pregnant: formik.values.pregnantImmunization,
          Consent: formik.values.consentImmunization,
          Relationship: formik.values.relationshipImmunization,
          Work: formik.values.workImmunization,

          PatientUid: "Online Form",
          Category: "Immunization Record",
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        await addDoc(collection(db, "History"), {
          Title: `Created Patient ${formik.values.fullNamePrenatal} Immunization Record`,
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        window.location.reload();
      } else if (schemaType === "Medicine") {
        await addDoc(collection(db, "Patients"), {
          FullName:
            formik.values.firstNamem +
            ' ' +
            formik.values.middleNamem +
            ' ' +
            formik.values.lastNamem,
          ResidentAddress: formik.values.residentAddressMedicine,
          MedicineName: formik.values.medicineName,
          MedicineQuantity: formik.values.medicineQuantity,
          
          PatientUid: user.users
            .filter((item) => item.UserName === formik.values.fullNameMedicine)
            .map((item) => item.id)
            .toString(),
          Category: "Medicine Record",
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        await addDoc(collection(db, "History"), {
          Title: `Created Patient ${formik.values.fullNameMedicine} Medicine Record`,
          CreatedUser: "Online Form",
          Created: serverTimestamp(),
        });

        const docRef = doc(db, "Medicines", 
        user.medicines
        .filter((item) => item.BrandName === formik.values.medicineName)
        .map((item) => item.id)
        .toString(),
        );
        const payload = {
          Quantity: increment(- formik.values.medicineQuantity),
          UpdateCreated: serverTimestamp(),
        };

        updateDoc(docRef, payload);
   


        resetForm({
          values: {
            fullNameTreatment: "",
            medicineName: "",
            medicineQuantity: "",
          
          },
        });
        setSubmitting(false);
      }
      
      else {
        alert("Error");
      }
    },
  });


  const {
    errors,
    touched,
    // values,
    resetForm,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    // getFieldMeta,
  } = formik;

  // mui tab
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* Navbar */}
      <Navbar isAuth={isAuth} />

      {/* Main Body */}
      <Box sx={style.mainContainer}>
        {/* Banner */}
        <Box sx={style.banner}></Box>

        {/* Promotional Space 1 */}
        <Box sx={style.promotionalSpaceContainer}>
          {/* title */}
          <Box sx={style.titleContainer}>
            <Typography sx={style.promotionalTitle}>
              Our Online Form
            </Typography>
          </Box>

          <Grid sx={{fontFamily: "sans-serif" }}>
          <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} >
              <Tab label="Regular Checkup" value="1" />
              <Tab label="Family Planning" value="2" />
              <Tab label="Prenatal" value="3" />
              <Tab label="Immunization" value="4" />
              <Tab label="Availment of Medicine" value="5" />
            
            </TabList>
          </Box>

          <TabPanel value="1">
            {" "}
            <Box sx={{ flexGrow: 1 }} >
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Item>
                        <Box
                          display="grid"
                          // gridTemplateColumns="repeat(12, 1fr)"
                          sx={style.boxGrid}
                          gap={2}
                        >
                          <Box gridColumn="span 12">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                            >
                              Regular Checkup
                            </Typography>
                          </Box>

                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="First Name"
                              {...getFieldProps('firstName')}
                              error={Boolean(
                                touched.firstName && errors.firstName
                              )}
                              helperText={touched.firstName && errors.firstName}
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Middle Name"
                              {...getFieldProps('middleName')}
                              error={Boolean(
                                touched.middleName && errors.middleName
                              )}
                              helperText={
                                touched.middleName && errors.middleName
                              }
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Last Name"
                              {...getFieldProps('lastName')}
                              error={Boolean(
                                touched.lastName && errors.lastName
                              )}
                              helperText={touched.lastName && errors.lastName}
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <FormControl
                              fullWidth
                              error={Boolean(touched.gender && errors.gender)}
                            >
                              <InputLabel>Gender</InputLabel>
                              <Select
                                {...getFieldProps("gender")}
                                label="Gender"
                              >
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.gender && errors.gender}
                              </FormHelperText>
                            </FormControl>
                          </Box>

                          <Box gridColumn="span 6">
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <DatePicker
                                name="date"
                                format="MM/dd/yyy"
                                label="Birth Date"
                                {...getFieldProps("birthDate")}
                                onChange={(value) =>
                                  setFieldValue("birthDate", value)
                                }
                                error={Boolean(
                                  touched.birthDate && errors.birthDate
                                )}
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Birth Place"
                              {...getFieldProps("birthPlace")}
                              error={Boolean(
                                touched.birthPlace && errors.birthPlace
                              )}
                              helperText={
                                touched.birthPlace && errors.birthPlace
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.bloodType && errors.bloodType
                              )}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Blood Type
                              </InputLabel>
                              <Select
                                {...getFieldProps("bloodType")}
                                label="Blood Type"
                              >
                                <MenuItem value={"A"}>A</MenuItem>
                                <MenuItem value={"B"}>B</MenuItem>
                                <MenuItem value={"AB"}>AB</MenuItem>
                                <MenuItem value={"O"}>O</MenuItem>
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.bloodType && errors.bloodType}
                              </FormHelperText>
                            </FormControl>
                          </Box>

                          <Box gridColumn="span 6">
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.civilStatus && errors.civilStatus
                              )}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Civil Status
                              </InputLabel>
                              <Select
                                {...getFieldProps("civilStatus")}
                                label="Civil Status"
                              >
                                <MenuItem value={"Married"}>Married</MenuItem>
                                <MenuItem value={"Widowed"}>Widowed</MenuItem>
                                <MenuItem value={"Separated"}>
                                  Separated
                                </MenuItem>
                                <MenuItem value={"Divorced"}>Divorced</MenuItem>
                                <MenuItem value={"Single "}>Single </MenuItem>
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.civilStatus && errors.civilStatus}
                              </FormHelperText>
                            </FormControl>
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Spouse Name"
                              {...getFieldProps("spouseName")}
                              error={Boolean(
                                touched.spouseName && errors.spouseName
                              )}
                              helperText={
                                touched.spouseName && errors.spouseName
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Resident Address"
                              {...getFieldProps("residentAddress")}
                              error={Boolean(
                                touched.residentAddress &&
                                  errors.residentAddress
                              )}
                              helperText={
                                touched.residentAddress &&
                                errors.residentAddress
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="Contact Number"
                              {...getFieldProps("contactNumber")}
                              error={Boolean(
                                touched.contactNumber && errors.contactNumber
                              )}
                              helperText={
                                touched.contactNumber && errors.contactNumber
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Family Member"
                              {...getFieldProps("familyMember")}
                              error={Boolean(
                                touched.familyMember && errors.familyMember
                              )}
                              helperText={
                                touched.familyMember && errors.familyMember
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Education Attainment"
                              {...getFieldProps("educationalAttainment")}
                              error={Boolean(
                                touched.educationalAttainment &&
                                  errors.educationalAttainment
                              )}
                              helperText={
                                touched.educationalAttainment &&
                                errors.educationalAttainment
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.fourpsMember && errors.fourpsMember
                              )}
                            >
                              <InputLabel id="demo-simple-select-label">
                                4ps Member
                              </InputLabel>
                              <Select
                                {...getFieldProps("fourpsMember")}
                                label="fourps Member"
                              >
                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                <MenuItem value={"No"}>No</MenuItem>
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.fourpsMember && errors.fourpsMember}
                              </FormHelperText>
                            </FormControl>
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="House Hold Number"
                              {...getFieldProps("houseNumber")}
                              error={Boolean(
                                touched.houseNumber && errors.houseNumber
                              )}
                              helperText={
                                touched.houseNumber && errors.houseNumber
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            my: 2,
                          }}
                        >
                          <LoadingButton
                            style={{
                              textTransform: "Capitalize",
                              borderRadius: 8,
                            }}
                            size="small"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => setSchemaType("Resident")}
                          >
                            Create Resident Information
                          </LoadingButton>
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </TabPanel>

          <TabPanel value="2">
            <Box sx={{ flexGrow: 1 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Item>
                        <Box
                          display="grid"
                          // gridTemplateColumns="repeat(12, 1fr)"
                          sx={style.boxGrid}
                          gap={2}
                        >
                          <Box gridColumn="span 12">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                            >
                              Family Planning
                            </Typography>
                          </Box>

                          

                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="First Name"
                              {...getFieldProps('firstNamef')}
                              error={Boolean(
                                touched.firstNamef && errors.firstNamef
                              )}
                              helperText={touched.firstNamef && errors.firstNamef}
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Middle Name"
                              {...getFieldProps('middleNamef')}
                              error={Boolean(
                                touched.middleNamef && errors.middleNamef
                              )}
                              helperText={
                                touched.middleNamef && errors.middleNamef
                              }
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Last Name"
                              {...getFieldProps('lastNamef')}
                              error={Boolean(
                                touched.lastNamef && errors.lastNamef
                              )}
                              helperText={touched.lastNamef && errors.lastNamef}
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Residential Address"
                              {...getFieldProps("residentAddressTreatment")}
                              error={Boolean(
                                touched.residentAddressTreatment &&
                                  errors.residentAddressTreatment
                              )}
                              helperText={
                                touched.residentAddressTreatment &&
                                errors.residentAddressTreatment
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="Age"
                              {...getFieldProps("age")}
                              error={Boolean(touched.age && errors.age)}
                              helperText={touched.age && errors.age}
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                              sx={{ mb: 1 }}
                            >
                              For CHU/RHU Only (Para sa kinatawan ng CHU/RHU
                              Lamang){" "}
                            </Typography>
                            <Box
                              display="grid"
                              // gridTemplateColumns="repeat(12, 1fr)"
                              sx={style.boxGrid}
                              gap={2}
                            >
                              <Box gridColumn="span 12">
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                  <DatePicker
                                    name="date"
                                    format="MM/dd/yyy"
                                    label="Date Constutation"
                                    {...getFieldProps("dateConstutation")}
                                    onChange={(value) =>
                                      setFieldValue("dateConstutation", value)
                                    }
                                    error={Boolean(
                                      touched.dateConstutation &&
                                        errors.dateConstutation
                                    )}
                                    renderInput={(params) => (
                                      <TextField fullWidth {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Bood Pressure"
                                  {...getFieldProps("bloodPressure")}
                                  error={Boolean(
                                    touched.bloodPressure &&
                                      errors.bloodPressure
                                  )}
                                  helperText={
                                    touched.bloodPressure &&
                                    errors.bloodPressure
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Temperature"
                                  {...getFieldProps("temperature")}
                                  error={Boolean(
                                    touched.temperature && errors.temperature
                                  )}
                                  helperText={
                                    touched.temperature && errors.temperature
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Weight"
                                  {...getFieldProps("weight")}
                                  error={Boolean(
                                    touched.weight && errors.weight
                                  )}
                                  helperText={touched.weight && errors.weight}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Purpose of Visit"
                                  {...getFieldProps("purpose")}
                                  error={Boolean(
                                    touched.purpose && errors.purpose
                                  )}
                                  helperText={touched.purpose && errors.purpose}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Diagnosis"
                                  {...getFieldProps("diagnosis")}
                                  error={Boolean(
                                    touched.diagnosis && errors.diagnosis
                                  )}
                                  helperText={
                                    touched.diagnosis && errors.diagnosis
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Medication Treatment"
                                  {...getFieldProps("medicationTreatment")}
                                  error={Boolean(
                                    touched.medicationTreatment &&
                                      errors.medicationTreatment
                                  )}
                                  helperText={
                                    touched.medicationTreatment &&
                                    errors.medicationTreatment
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Laboratory Finding"
                                  {...getFieldProps("laboratoryFinding")}
                                  error={Boolean(
                                    touched.laboratoryFinding &&
                                      errors.laboratoryFinding
                                  )}
                                  helperText={
                                    touched.laboratoryFinding &&
                                    errors.laboratoryFinding
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box gridColumn="span 6">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                              sx={{ mb: 1 }}
                            >
                              For Refferal Transaction Only (Para sa pag
                              Pagsangguni lamang){""}
                            </Typography>
                            <Box
                              display="grid"
                              // gridTemplateColumns="repeat(12, 1fr)"
                              sx={style.boxGrid}
                              gap={2}
                            >
                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Reffered from"
                                  {...getFieldProps("refferedFrom")}
                                  error={Boolean(
                                    touched.refferedFrom && errors.refferedFrom
                                  )}
                                  helperText={
                                    touched.refferedFrom && errors.refferedFrom
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Reffered to"
                                  {...getFieldProps("refferedTo")}
                                  error={Boolean(
                                    touched.refferedTo && errors.refferedTo
                                  )}
                                  helperText={
                                    touched.refferedTo && errors.refferedTo
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Reason for Refferal"
                                  {...getFieldProps("reasonRefferal")}
                                  error={Boolean(
                                    touched.reasonRefferal &&
                                      errors.reasonRefferal
                                  )}
                                  helperText={
                                    touched.reasonRefferal &&
                                    errors.reasonRefferal
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Reffered By"
                                  {...getFieldProps("refferedBy")}
                                  error={Boolean(
                                    touched.refferedBy && errors.refferedBy
                                  )}
                                  helperText={
                                    touched.refferedBy && errors.refferedBy
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Chief Complaint"
                                  {...getFieldProps("chiefComplaint")}
                                  error={Boolean(
                                    touched.chiefComplaint &&
                                      errors.chiefComplaint
                                  )}
                                  helperText={
                                    touched.chiefComplaint &&
                                    errors.chiefComplaint
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            my: 2,
                          }}
                        >
                          <LoadingButton
                            style={{
                              textTransform: "Capitalize",
                              borderRadius: 8,
                            }}
                            size="small"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => setSchemaType("Treatment")}
                          >
                            Create Treatment Record
                          </LoadingButton>
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </TabPanel>

          <TabPanel value="3">
            <Box sx={{ flexGrow: 1 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Item>
                        <Box
                          display="grid"
                          // gridTemplateColumns="repeat(12, 1fr)"
                          sx={style.boxGrid}
                          gap={2}
                        >
                          <Box gridColumn="span 12">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                            >
                              Prenatal
                            </Typography>
                          </Box>

                       

                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="First Name"
                              {...getFieldProps('firstNamep')}
                              error={Boolean(
                                touched.firstNamep && errors.firstNamep
                              )}
                              helperText={touched.firstNamep && errors.firstNamep}
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Middle Name"
                              {...getFieldProps('middleNamep')}
                              error={Boolean(
                                touched.middleNamep && errors.middleNamep
                              )}
                              helperText={
                                touched.middleNamep && errors.middleNamep
                              }
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Last Name"
                              {...getFieldProps('lastNamep')}
                              error={Boolean(
                                touched.lastNamep && errors.lastNamep
                              )}
                              helperText={touched.lastNamep && errors.lastNamep}
                            />
                          </Box>

                          <Box gridColumn="span 12">
                            <TextField
                              fullWidth
                              type="name"
                              label="Residential Address"
                              {...getFieldProps("residentAddressPrenatal")}
                              error={Boolean(
                                touched.residentAddressPrenatal &&
                                  errors.residentAddressPrenatal
                              )}
                              helperText={
                                touched.residentAddressPrenatal &&
                                errors.residentAddressPrenatal
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="Contact Number"
                              {...getFieldProps("contactNumberPrenatal")}
                              error={Boolean(
                                touched.contactNumberPrenatal &&
                                  errors.contactNumberPrenatal
                              )}
                              helperText={
                                touched.contactNumberPrenatal &&
                                errors.contactNumberPrenatal
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="Age"
                              {...getFieldProps("agePrenatal")}
                              error={Boolean(
                                touched.agePrenatal && errors.agePrenatal
                              )}
                              helperText={
                                touched.agePrenatal && errors.agePrenatal
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                              sx={{ my: 4 }}
                            >
                              {" "}
                            </Typography>
                            <Box
                              display="grid"
                              // gridTemplateColumns="repeat(12, 1fr)"
                              sx={style.boxGrid}
                              gap={2}
                            >
                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Gravidity"
                                  {...getFieldProps("gravidity")}
                                  error={Boolean(
                                    touched.gravidity && errors.gravidity
                                  )}
                                  helperText={
                                    touched.gravidity && errors.gravidity
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Parity"
                                  {...getFieldProps("parity")}
                                  error={Boolean(
                                    touched.parity && errors.parity
                                  )}
                                  helperText={touched.parity && errors.parity}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Term"
                                  {...getFieldProps("term")}
                                  error={Boolean(touched.term && errors.term)}
                                  helperText={touched.term && errors.term}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Preterm"
                                  {...getFieldProps("preterm")}
                                  error={Boolean(
                                    touched.preterm && errors.preterm
                                  )}
                                  helperText={touched.preterm && errors.preterm}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Live Birth"
                                  {...getFieldProps("liveBirth")}
                                  error={Boolean(
                                    touched.liveBirth && errors.liveBirth
                                  )}
                                  helperText={
                                    touched.liveBirth && errors.liveBirth
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Abortion"
                                  {...getFieldProps("abortion")}
                                  error={Boolean(
                                    touched.abortion && errors.abortion
                                  )}
                                  helperText={
                                    touched.abortion && errors.abortion
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Syphilis"
                                  {...getFieldProps("syphilis")}
                                  error={Boolean(
                                    touched.syphilis && errors.syphilis
                                  )}
                                  helperText={
                                    touched.syphilis && errors.syphilis
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box gridColumn="span 6">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                              sx={{ my: 4 }}
                            >
                              {""}
                            </Typography>
                            <Box
                              display="grid"
                              // gridTemplateColumns="repeat(12, 1fr)"
                              sx={style.boxGrid}
                              gap={2}
                            >
                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="LMP"
                                  {...getFieldProps("lmp")}
                                  error={Boolean(touched.lmp && errors.lmp)}
                                  helperText={touched.lmp && errors.lmp}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="EDC"
                                  {...getFieldProps("edc")}
                                  error={Boolean(touched.edc && errors.edc)}
                                  helperText={touched.edc && errors.edc}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="AOG"
                                  {...getFieldProps("aog")}
                                  error={Boolean(touched.aog && errors.aog)}
                                  helperText={touched.aog && errors.aog}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="TI"
                                  {...getFieldProps("ti")}
                                  error={Boolean(touched.ti && errors.ti)}
                                  helperText={touched.ti && errors.ti}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Iron"
                                  {...getFieldProps("iron")}
                                  error={Boolean(touched.iron && errors.iron)}
                                  helperText={touched.iron && errors.iron}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Others"
                                  {...getFieldProps("others")}
                                  error={Boolean(
                                    touched.others && errors.others
                                  )}
                                  helperText={touched.others && errors.others}
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Penicillin"
                                  {...getFieldProps("penicillin")}
                                  error={Boolean(
                                    touched.penicillin && errors.penicillin
                                  )}
                                  helperText={
                                    touched.penicillin && errors.penicillin
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box gridColumn="span 12">
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <DatePicker
                                name="date"
                                format="MM/dd/yyy"
                                label="Schedule Next Visit"
                                {...getFieldProps("scheduleNextVisit")}
                                onChange={(value) =>
                                  setFieldValue("scheduleNextVisit", value)
                                }
                                error={Boolean(
                                  touched.scheduleNextVisit &&
                                    errors.scheduleNextVisit
                                )}
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>

                          <Box gridColumn="span 12">
                            <TextField
                              fullWidth
                              type="name"
                              label="Fundic Height"
                              {...getFieldProps("fundicHeight")}
                              error={Boolean(
                                touched.fundicHeight && errors.fundicHeight
                              )}
                              helperText={
                                touched.fundicHeight && errors.fundicHeight
                              }
                            />
                          </Box>

                          <Box gridColumn="span 12">
                            <TextField
                              fullWidth
                              type="name"
                              label="Fatal Heart Tone"
                              {...getFieldProps("fatalHeartTone")}
                              error={Boolean(
                                touched.fatalHeartTone && errors.fatalHeartTone
                              )}
                              helperText={
                                touched.fatalHeartTone && errors.fatalHeartTone
                              }
                            />
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            my: 2,
                          }}
                        >
                          <LoadingButton
                            style={{
                              textTransform: "Capitalize",
                              borderRadius: 8,
                            }}
                            size="small"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => setSchemaType("Prenatal")}
                          >
                            Create Prenatal Record
                          </LoadingButton>
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </TabPanel>

          <TabPanel value="4">
            <Box sx={{ flexGrow: 1 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Item>
                        <Box
                          display="grid"
                          // gridTemplateColumns="repeat(12, 1fr)"
                          sx={style.boxGrid}
                          gap={2}
                        >
                          <Box gridColumn="span 12">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                            >
                              Immunization
                            </Typography>
                          </Box>

                     

                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="First Name"
                              {...getFieldProps('firstNamei')}
                              error={Boolean(
                                touched.firstNamei && errors.firstNamei
                              )}
                              helperText={touched.firstNamei && errors.firstNamei}
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Middle Name"
                              {...getFieldProps('middleNamei')}
                              error={Boolean(
                                touched.middleNamei && errors.middleNamei
                              )}
                              helperText={
                                touched.middleNamei && errors.middleNamei
                              }
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Last Name"
                              {...getFieldProps('lastNamei')}
                              error={Boolean(
                                touched.lastNamei && errors.lastNamei
                              )}
                              helperText={touched.lastNamei && errors.lastNamei}
                            />
                          </Box>

                          <Box gridColumn="span 12">
                            <TextField
                              fullWidth
                              type="name"
                              label="Residential Address"
                              {...getFieldProps("residentAddressImmunization")}
                              error={Boolean(
                                touched.residentAddressImmunization &&
                                  errors.residentAddressImmunization
                              )}
                              helperText={
                                touched.residentAddressImmunization &&
                                errors.residentAddressImmunization
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="Contact Number"
                              {...getFieldProps("contactNumberImmunization")}
                              error={Boolean(
                                touched.contactNumberImmunization &&
                                  errors.contactNumberImmunization
                              )}
                              helperText={
                                touched.contactNumberImmunization &&
                                errors.contactNumberImmunization
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="number"
                              label="Age"
                              {...getFieldProps("ageImmunization")}
                              error={Boolean(
                                touched.ageImmunization &&
                                  errors.ageImmunization
                              )}
                              helperText={
                                touched.ageImmunization &&
                                errors.ageImmunization
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="Grade"
                              {...getFieldProps("gradeImmunization")}
                              error={Boolean(
                                touched.gradeImmunization &&
                                  errors.gradeImmunization
                              )}
                              helperText={
                                touched.gradeImmunization &&
                                errors.gradeImmunization
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <TextField
                              fullWidth
                              type="name"
                              label="School"
                              {...getFieldProps("schoolImmunization")}
                              error={Boolean(
                                touched.schoolImmunization &&
                                  errors.schoolImmunization
                              )}
                              helperText={
                                touched.schoolImmunization &&
                                errors.schoolImmunization
                              }
                            />
                          </Box>

                          <Box gridColumn="span 12">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                              sx={{ my: 2 }}
                            >
                              {" "}
                            </Typography>
                            <Box
                              display="grid"
                              // gridTemplateColumns="repeat(12, 1fr)"
                              sx={style.boxGrid}
                              gap={2}
                            >
                              <Box gridColumn="span 12">
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                  <DatePicker
                                    name="date"
                                    format="MM/dd/yyy"
                                    label="Birth Date"
                                    {...getFieldProps("dateBirthImmunization")}
                                    onChange={(value) =>
                                      setFieldValue(
                                        "dateBirthImmunization",
                                        value
                                      )
                                    }
                                    error={Boolean(
                                      touched.dateBirthImmunization &&
                                        errors.dateBirthImmunization
                                    )}
                                    renderInput={(params) => (
                                      <TextField fullWidth {...params} />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Does your child havae any allergies?"
                                  {...getFieldProps("allergiesImmunization")}
                                  error={Boolean(
                                    touched.allergiesImmunization &&
                                      errors.allergiesImmunization
                                  )}
                                  helperText={
                                    touched.allergiesImmunization &&
                                    errors.allergiesImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Does your child have any medical conditions that require regular visits to a doctor?"
                                  {...getFieldProps(
                                    "healthConditionsImmunization"
                                  )}
                                  error={Boolean(
                                    touched.healthConditionsImmunization &&
                                      errors.healthConditionsImmunization
                                  )}
                                  helperText={
                                    touched.healthConditionsImmunization &&
                                    errors.healthConditionsImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Has your child ever had chikenpox?"
                                  {...getFieldProps("chickenPoxImmunization")}
                                  error={Boolean(
                                    touched.chickenPoxImmunization &&
                                      errors.chickenPoxImmunization
                                  )}
                                  helperText={
                                    touched.chickenPoxImmunization &&
                                    errors.chickenPoxImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Has your child ever had chikenpox vaccine?"
                                  {...getFieldProps(
                                    "checkenPoxVaccineImmunization"
                                  )}
                                  error={Boolean(
                                    touched.checkenPoxVaccineImmunization &&
                                      errors.checkenPoxVaccineImmunization
                                  )}
                                  helperText={
                                    touched.checkenPoxVaccineImmunization &&
                                    errors.checkenPoxVaccineImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Has your had a reaction to a vaccine?"
                                  {...getFieldProps(
                                    "reactionVaccineImmunization"
                                  )}
                                  error={Boolean(
                                    touched.reactionVaccineImmunization &&
                                      errors.reactionVaccineImmunization
                                  )}
                                  helperText={
                                    touched.reactionVaccineImmunization &&
                                    errors.reactionVaccineImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Is your child pregnant"
                                  {...getFieldProps("pregnantImmunization")}
                                  error={Boolean(
                                    touched.pregnantImmunization &&
                                      errors.pregnantImmunization
                                  )}
                                  helperText={
                                    touched.pregnantImmunization &&
                                    errors.pregnantImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.consentImmunization &&
                                      errors.consentImmunization
                                  )}
                                >
                                  <InputLabel>
                                    Check one of the following four options
                                  </InputLabel>
                                  <Select
                                    {...getFieldProps("consentImmunization")}
                                    label="Check one of the following four options"
                                  >
                                    <MenuItem
                                      value={
                                        "Yes - I DO consent to the person name above receiving the vaccines's identified above"
                                      }
                                    >
                                      Yes - I DO consent to the person name
                                      above receiving the vaccines's identified
                                      above
                                    </MenuItem>
                                    <MenuItem
                                      value={
                                        "Yes - I DO consent to the person name above receiving the vaccines's"
                                      }
                                    >
                                      Yes - I DO consent to the person name
                                      above receiving the vaccines's
                                    </MenuItem>
                                    <MenuItem
                                      value={
                                        "No - I DO NOT consent to the person name above receiving the vaccines's identified above"
                                      }
                                    >
                                      No - I DO NOT consent to the person name
                                      above receiving the vaccines's identified
                                      above
                                    </MenuItem>
                                  </Select>
                                  <FormHelperText sx={{ color: "red" }}>
                                    {touched.consentImmunization &&
                                      errors.consentImmunization}
                                  </FormHelperText>
                                </FormControl>
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Relationship"
                                  {...getFieldProps("relationshipImmunization")}
                                  error={Boolean(
                                    touched.relationshipImmunization &&
                                      errors.relationshipImmunization
                                  )}
                                  helperText={
                                    touched.relationshipImmunization &&
                                    errors.relationshipImmunization
                                  }
                                />
                              </Box>

                              <Box gridColumn="span 12">
                                <TextField
                                  fullWidth
                                  type="name"
                                  label="Work"
                                  {...getFieldProps("workImmunization")}
                                  error={Boolean(
                                    touched.workImmunization &&
                                      errors.workImmunization
                                  )}
                                  helperText={
                                    touched.workImmunization &&
                                    errors.workImmunization
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            my: 2,
                          }}
                        >
                          <LoadingButton
                            disabled={
                              formik.values.consentImmunization ===
                              "No - I DO NOT consent to the person name above receiving the vaccines's identified above"
                            }
                            style={{
                              textTransform: "Capitalize",
                              borderRadius: 8,
                            }}
                            size="small"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => setSchemaType("Immunization")}
                          >
                            Create Immunization Record
                          </LoadingButton>
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </TabPanel>

          <TabPanel value="5">
            <Box sx={{ flexGrow: 1 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Item>
                        <Box
                          display="grid"
                          // gridTemplateColumns="repeat(12, 1fr)"
                          sx={style.boxGrid}
                          gap={2}
                        >
                          <Box gridColumn="span 12">
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                            >
                              Availment of Medicine
                            </Typography>
                          </Box>


                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="First Name"
                              {...getFieldProps('firstNamem')}
                              error={Boolean(
                                touched.firstNamem && errors.firstNamem
                              )}
                              helperText={touched.firstNamem && errors.firstNamem}
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Middle Name"
                              {...getFieldProps('middleNamem')}
                              error={Boolean(
                                touched.middleNamem && errors.middleNamem
                              )}
                              helperText={
                                touched.middleNamem && errors.middleNamem
                              }
                            />
                          </Box>
                          <Box gridColumn="span 4">
                            <TextField
                              fullWidth
                              type="name"
                              label="Last Name"
                              {...getFieldProps('lastNamem')}
                              error={Boolean(
                                touched.lastNamem && errors.lastNamem
                              )}
                              helperText={touched.lastNamem && errors.lastNamem}
                            />
                          </Box>

                          <Box gridColumn="span 12">
                            <TextField
                              fullWidth
                              type="name"
                              label="Address"
                              {...getFieldProps("residentAddressMedicine")}
                              error={Boolean(
                                touched.residentAddressMedicine &&
                                  errors.residentAddressMedicine
                              )}
                              helperText={
                                touched.residentAddressMedicine &&
                                errors.residentAddressMedicine
                              }
                            />
                          </Box>

                          <Box gridColumn="span 6">
                            <FormControl
                              fullWidth
                              error={Boolean(
                                touched.medicineName && errors.medicineName
                              )}
                            >
                              <InputLabel>Medicine Name</InputLabel>
                              <Select
                                {...getFieldProps("medicineName")}
                                label="Medicine Name"
                              >
                                {user.medicines
                                  .filter((item) => item.Quantity > 0)
                                  .map((item) => (
                                    <MenuItem
                                      // value={`${item.BrandName}  ${item.GenericName}`}
                                      value={item.BrandName}
                                    >
                                      {`${item.BrandName}  ${item.GenericName}`}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.medicineName && errors.medicineName}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                          {/* Medicine Qty no validation */}
                          <Box gridColumn="span 6">
                            <FormControl
                              disabled={formik.values.medicineName === ""}
                              fullWidth
                              error={Boolean(
                                touched.medicineQuantity &&
                                  errors.medicineQuantity
                              )}
                            >
                              <InputLabel>Quanity</InputLabel>
                              <Select
                                {...getFieldProps("medicineQuantity")}
                                label="Quanity"
                              >
                                <MenuItem value={"1"}>1</MenuItem>
                                <MenuItem value={"2"}>2</MenuItem>
                                <MenuItem value={"3"}>3</MenuItem>
                                <MenuItem value={"4"}>4</MenuItem>
                                <MenuItem value={"5"}>5</MenuItem>
                                <MenuItem value={"6"}>6</MenuItem>
                                <MenuItem value={"7"}>7</MenuItem>
                                <MenuItem value={"8"}>8</MenuItem>
                                <MenuItem value={"9"}>9</MenuItem>
                                <MenuItem value={"10"}>10</MenuItem>
                              </Select>
                              <FormHelperText sx={{ color: "red" }}>
                                {touched.medicineQuantity &&
                                  errors.medicineQuantity}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            my: 2,
                          }}
                        >
                          <LoadingButton
                            style={{
                              textTransform: "Capitalize",
                              borderRadius: 8,
                            }}
                            size="small"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            onClick={() => setSchemaType("Medicine")}
                          >
                            Create Resident Information
                          </LoadingButton>
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </TabPanel>
          <TabPanel value="6">Item Three</TabPanel>
        </TabContext>

            
          </Grid>
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Forms;
