import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import theme from "../utils/theme";

import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";
import Doctor from "../pages/Doctor";
import Profile from "../pages/Profile";
import Appointment from "../pages/Appointment";
import Loading from "../components/Loading";
import ForgotPassword from "../pages/ForgotPassword";
import Forms from "../pages/Forms";
import Developers from "../pages/Developers";
import Contact from "../pages/Contact";

// backend
import { onAuthStateChanged } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { auth, db } from "../utils/firebase";
import { getTheme, getLang } from "../redux/actions/uiAction";
import {
  where,
  collection,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import {
  setNews,
  setMyData,
  setUsers,
  setAppointments,
  setPatients,
} from "../redux/actions/userAction";

import PublicRoute from "./publicRoute";
import PrivateRoute from "./privateRoute";
import Services from "../pages/Services";
export default function Routes() {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));
  const [state, setstate] = useState({
    isAuth: false,
    isLoading: true,
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // console.log(user.uid);

       // news and blogs
       const collectionRefNewsBlogs = collection(db, "News");
       const qNewsBlogs = query(collectionRefNewsBlogs, orderBy("Created"));
       onSnapshot(qNewsBlogs, (snapshot) =>
         dispatch(
           setNews(
             snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
           )
         )
       );

      // users
        const collectionRefUsers = collection(db, "Users");
        const qUsers = query(collectionRefUsers, orderBy("Created"));
        onSnapshot(qUsers, (snapshot) =>
          dispatch(
            setUsers(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

      if (user && user.emailVerified) {
        const collectionRefMyData = collection(db, "Users");
        const MyData = query(
          collectionRefMyData,
          where("UserUid", "==", user.uid)
        );

        onSnapshot(MyData, (snapshot) => {
          if (
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]
              .UserType === "Patient"
          ) {
            dispatch(
              setMyData(
                snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
              )
            );

            setstate({ isAuth: true, isLoading: false });
          
          } else {
            setstate({ isAuth: false, isLoading: false });
            auth.signOut();
            alert("Your Account is not for Patient");
            console.log("Your Account is not for Patient");
          }
        });

        // Appointment
        const collectionRefAppointments = collection(db, "Appointment");
        const qAppointments = query(collectionRefAppointments, orderBy("Created"));
        onSnapshot(qAppointments, (snapshot) =>
          dispatch(
            setAppointments(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

         // Patients
         const collectionRefPatients = collection(db, "Patients");
         const qPatients = query(collectionRefPatients, orderBy("Created"));
         onSnapshot(qPatients, (snapshot) =>
           dispatch(
             setPatients(
               snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
             )
           )
         );


        //  // MY Appointment
        //  const collectionRefAppointments = collection(db, "Appointment");
        //  const qAppointments = query(collectionRefAppointments, orderBy("Created"));
        //  onSnapshot(qAppointments, (snapshot) =>
        //    dispatch(
        //      setAppointments(
        //        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //      )
        //    )
        //  );


        // //users
        // const collectionRefUsers = collection(db, "Users");
        // const qUsers = query(collectionRefUsers, orderBy("Created"));
        // onSnapshot(qUsers, (snapshot) =>
        //   dispatch(
        //     setUsers(
        //       snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //     )
        //   )
        // );

        // //products
        // const collectionRefProducts = collection(db, "Products");
        // const qProducts = query(collectionRefProducts, orderBy("Created"));
        // onSnapshot(qProducts, (snapshot) =>
        //   dispatch(
        //     setProducts(
        //       snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //     )
        //   )
        // );

        // //news and blogs
        // const collectionRefNewsBlogs = collection(db, "NewsBlogs");
        // const qNewsBlogs = query(collectionRefNewsBlogs, orderBy("Created"));
        // onSnapshot(qNewsBlogs, (snapshot) =>
        //   dispatch(
        //     setNewsBlogs(
        //       snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //     )
        //   )
        // );

        // //Orders
        // const collectionRefOrders = collection(db, "Orders");
        // const qOrders = query(collectionRefOrders, orderBy("CreatedAt"));
        // onSnapshot(qOrders, (snapshot) =>
        //   dispatch(
        //     setOrders(
        //       snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //     )
        //   )
        // );

        //  //Finance
        //  const collectionRefFinance = collection(db, "Finance");
        //  const qFinance = query(collectionRefFinance, orderBy("Created"));
        //  onSnapshot(qFinance, (snapshot) =>
        //    dispatch(
        //      setFinance(
        //        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //      )
        //    )
        //  );

        // setstate({ isAuth: true, isLoading: false });
      } else {
        setstate({ isAuth: false, isLoading: false });
      }
    });

    dispatch(getLang(), getTheme());
  }, [dispatch]);
  console.log(state.isAuth);
  if (state.isLoading) {
    return <Loading />;
  }
  return (
    <ThemeProvider theme={THEME}>
      <Router>
        <Switch>
          <PublicRoute isAuth={state.isAuth} exact path="/" component={Home} />
          <PublicRoute isAuth={state.isAuth} exact path="/about" component={About} />
          <PublicRoute isAuth={state.isAuth} exact path="/doctor" component={Doctor} />
          <PublicRoute isAuth={state.isAuth} exact path="/appointment" component={Appointment} />
          <PublicRoute isAuth={state.isAuth} exact path="/forgot-password" component={ForgotPassword} />
          <PublicRoute isAuth={state.isAuth} exact path="/form" component={Forms} />
          <PublicRoute isAuth={state.isAuth} exact path="/developers" component={Developers} />
          <PublicRoute isAuth={state.isAuth} exact path="/contact" component={Contact} />
          <PublicRoute isAuth={state.isAuth} exact path="/services" component={Services} />
          <PrivateRoute
            isAuth={state.isAuth}
            exact
            path="/profile"
            component={Profile}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
