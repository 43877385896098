const style = {
    mainContainer: {
        width: '100%',
        height: '90px',
        backgroundColor: '#16304d',
        display: 'flex',
        alignItems: 'center',
        position: {
            xs: 'static',
            md: 'fixed',
        },
        zIndex: {
            xs: '0',
            md: '5',
        },

        top: 0,
    },

    middleContainer: {
        width: {
            xs: '95%',
            sm: '80%',
            md: '80%',
            lg: '80%',
        },
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    logoContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    logoTitle: {
        display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
        },

        flexDirection: 'column',
    },

    alternateLogoTitle: {
        display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
        },

        flexDirection: 'column',
    },

    logoTextBig: {
        fontFamily: 'semibold',
        fontSize: {
            xs: '13px',
            md: '15px',
        },
        textTransform: 'uppercase',
        color: '#fff',
        lineHeight: '20px',
    },

    logoTextSmall: {
        fontFamily: 'poppins',
        fontSize: '12px',
        color: '#fff',
    },

    linkList: {
        display: 'flex',
        alignItems: 'center',
    },

    buttonAndStuff: {
        display: {
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'flex',
        },
        alignItems: 'center',
    },

    navLink: {
        fontFamily: 'poppins',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        color: '#fff',
    },

    navLinkProfile: {
        fontFamily: 'poppins',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        color: '#fff',
        // display: 'none',
    },

    buttonProfileContainer: {
        display: 'flex',
    },

    loginButton: {
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        width: '80px',
        boxShadow: 0,
    },

    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#fff',
        border: '1px solid #e6e6e6',
        boxShadow: 24,
        p: '5px',
        width: {
            xs: '80%',
            sm: '60%',
            md: '40%',
            lg: '30%',
        },
    },

    logregTabs: {
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        color: '#000',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',

        "& .MuiTab-iconWrapper": {
            marginBottom: '0px',
        },
    },

    modalBig: {
        fontFamily: 'semibold',
        marginBottom: '5px',
    },

    modalSmall: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#4d4f4f',
    },

    textContainer: {
        marginTop: '20px',
    },

    modalLabel: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#4d4f4f',
    },

    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#cccccc',
            },
            '&:hover fieldset': {
                borderColor: '#479923',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #479923',
            },

            fontFamily: 'Poppins',
            fontSize: '12px',
            padding: '10px',
            height: '45px',
            borderRadius: 0,
        },

        width: '100%',
        backgroundColor: '#fff',

        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
            backgroundColor: '#fff',
        },
    },

    loginAccountButton: {
        width: '100%',
        boxShadow: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        backgroundColor: '#479923',
    },

    forgotPasswordContainer: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
    },

    forgotpassLink: {
        fontFamily: 'semibold',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        color: '#16304d',
        marginLeft: '5px',
    },

    menuContainer: {
        display: {
            xs: 'flex',
            sm: 'flex',
            md: 'flex',
            lg: 'none',
        },
    },

    drawerLinkContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    perLink: {
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px 5px 15px',
    },

    perLinkText: {
        fontFamily: 'poppins',
        color: '#4d4f4f',
        fontSize: '15px',
        marginLeft: '10px',
    },
    logout: {
        fontFamily: 'poppins',
        color: '#4d4f4f',
        fontSize: '15px',
        marginLeft: '10px',
        cursor: 'pointer',
    },

    drawerLogoContainer: {
        width: '100%',
        height: '90px',
        backgroundColor: '#16304d',
        display: 'flex',
        alignItems: 'center',
    },

    logoDrawerWrapper: {
        width: '90%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
    },

    loginTextDrawer: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#4d4f4f',
        marginBottom: '5px',
    },

    noLoginContainer: {
        padding: '10px',
        display: 'none',
    },

    drawerLoginButton: {
        width: '100%',
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',
    },

    loginContainer: {
        marginTop: '30px',
        padding: '10px',
    },

    profileDrawerName: {
        fontFamily: 'semibold',
        fontSize: '15px',
        color: '#16304d',
        marginBottom: '-12px',
        marginLeft: '25px',
    },
}
export default style;