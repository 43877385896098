import React from "react";

import style from "../styles/Modal";
import {
  Box,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useSelector } from "react-redux";
export default function ModalViewRecord({ recordId, open, handleOpen }) {
  const user = useSelector((state) => state.user);
  console.log(recordId);
  return (
    <Modal
      //   id={id}
      open={open}
      onClose={() => {
        handleOpen();
      }}
      sx={{ overflow: "scroll" }}
    >
      <Box sx={style.boxModal}>
        <Box sx={style.modalContainer}>
          <Box sx={style.headerModal}>
            <ReceiptIcon sx={style.modalIcon} />
            <Typography sx={style.modalHeadText}>
              {" "}
              {user.patients
                .filter((item) => item.id === recordId)
                .map((item) => item.Category)}
            </Typography>
          </Box>
          {/* Treatment Record */}

          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
            {user.patients
              .filter((item) => item.id === recordId)
              .map((item) => item.Category)
              .toString() === "Treatment Record" ? (
              <Box gridColumn="span 6">
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.FullName)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Diagnosis:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Diagnosis)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Puropose of Visit:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.PurposeVisit)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Blood Pressure:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.BloodPressure)}
                  </Typography>
                </Box>
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Temperature:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Temperature)}
                  </Typography>
                </Box>
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Weight:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Weight)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Medical Treatment:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.MedicationTreatment)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Laboratory finding:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.LaboratoryFinding)}
                  </Typography>
                </Box>
              </Box>
            ) : null}
         {/* Immunization Record */}
            {user.patients
              .filter((item) => item.id === recordId)
              .map((item) => item.Category)
              .toString() === "Immunization Record" ? (
              <Box gridColumn="span 6">
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.FullName)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Age:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Age)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Grade:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Grade)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    School:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.School)}
                  </Typography>
                </Box>
                
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Birth Date:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.DateBirth)}
                  </Typography>
                </Box>
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Does your child havae any allergies?{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Allergies)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Does your child have any medical conditions that require
                    regular visits to a doctor?{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.HealthCondition)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Has your child ever had chikenpox?{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.ChickenPox)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Has your child ever had chikenpox vaccine?{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.ChickenPoxVaccine)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Has your had a reaction to a vaccine?{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.ReactionChickenPoxVaccine)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Is your child pregnant?{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Pregnant)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Consent: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Consent)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Relationship: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Relationship)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Work: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Work)}
                  </Typography>
                </Box>

              </Box>
            ) : null}

   {/* Prenatal Record */}
   {user.patients
              .filter((item) => item.id === recordId)
              .map((item) => item.Category)
              .toString() === "Prenatal Record" ? (
              <Box gridColumn="span 6">
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.FullName)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Age:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Age)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Gravidity:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Gravidity)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    LMP:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.LMP)}
                  </Typography>
                </Box>
                
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Parity:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Parity)}
                  </Typography>
                </Box>
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                   EDC:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.EDC)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                   Term:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Term)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    AOG: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.AOG)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Preterm:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Preterm)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    TI:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.TI)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Live Birth{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.LiveBirth)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Iron: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Iron)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Abortion: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Abortion)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Others: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Others)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Syphilis: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.Syphilis)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Schedule Next Visit: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.ScheduleNextVisit)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Fundic Height: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.FundicHeight)}
                  </Typography>
                </Box>
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Fatal Hearth Tone: {" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.FatalHeartTone)}
                  </Typography>
                </Box>

              </Box>
            ) : null}

{user.patients
              .filter((item) => item.id === recordId)
              .map((item) => item.Category)
              .toString() === "Medicine Record" ? (
              <Box gridColumn="span 6">
                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.FullName)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Medicine:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.MedicineName)}
                  </Typography>
                </Box>

                <Box gridColumn="span 6">
                  <Typography color="textPrimary">
                    Quatity:{" "}
                    {user.patients
                      .filter((item) => item.id === recordId)
                      .map((item) => item.MedicineQuantity)}
                  </Typography>
                </Box>


              </Box>
            ) : null}


            <Box gridColumn="span 6">
              <Box gridColumn="span 6">
                <Typography color="textPrimary">
                  {user.users
                    .filter(
                      (item) =>
                        item.UserUid ===
                        user.patients
                          .filter((item) => item.id === recordId)
                          .map((item) => item.CreatedUser)
                          .toString()
                    )
                    .map((item) => item.UserType)
                    .toString() === "Doctor"
                    ? "Dr. "
                    : "Admin "}

                  {user.users
                    .filter(
                      (item) =>
                        item.UserUid ===
                        user.patients
                          .filter((item) => item.id === recordId)
                          .map((item) => item.CreatedUser)
                          .toString()
                    )
                    .map((item) => item.UserName)}
                </Typography>
              </Box>

              <Box gridColumn="span 6">
                <Typography color="textPrimary">
                  {user.users
                    .filter(
                      (item) =>
                        item.UserUid ===
                        user.patients
                          .filter((item) => item.id === recordId)
                          .map((item) => item.CreatedUser)
                          .toString()
                    )
                    .map((item) => item.UserType)}
                </Typography>
              </Box>

              <Box gridColumn="span 6">
                <Typography color="textPrimary">
                  {user.users
                    .filter(
                      (item) =>
                        item.UserUid ===
                        user.patients
                          .filter((item) => item.id === recordId)
                          .map((item) => item.CreatedUser)
                          .toString()
                    )
                    .map((item) => item.UserType)
                    .toString() === "Doctor"
                    ? "Specialties: "
                    : " "}

                  {user.users
                    .filter(
                      (item) =>
                        item.UserUid ===
                        user.patients
                          .filter((item) => item.id === recordId)
                          .map((item) => item.CreatedUser)
                          .toString()
                    )
                    .map((item) => item.Specialties)}
                </Typography>
              </Box>

              {/* <Box gridColumn="span 6">
                <Typography color="textPrimary">
                  {user.patients
                    .filter((item) => item.id === recordId)
                    .map((item) => item.FullName)}
                </Typography>
              </Box> */}
            </Box>
          </Box>
          {/* <Box sx={style.perItemModal}>
            <Button
              sx={style.uploadButton}
              onClick={updatePatientTreatment}
              disabled={disableEdit}
            >
              Update Patient Information
            </Button>
          </Box> */}

          <Box sx={style.perItemModal}>
            <Button sx={style.logoutButton} onClick={() => handleOpen()}>
              Cancel
            </Button>
          </Box>

          <Box sx={style.perItemModal}>
            {/* <Button sx={style.saveButton} onClick={() => buttonAcceptOrder()}>{user.orders.filter((order) => order.id === id)[0].JntTracking === undefined ? "Accept Order" : "Update" }</Button> */}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
