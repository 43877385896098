import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styling Page
import style from '../styles/Navbar';

// Import Image
import logo from '../image/Angat_Logo.png';

// Import Icon
import MenuIcon from '@mui/icons-material/Menu';

// Import Material UIs
import {
  Box,
  Typography,
  Button,
  TextField,
  Modal,
  List,
  ListItem,
  IconButton,
  Tabs,
  Tab,
  Drawer,
  Avatar,
} from '@mui/material';

// backend
import { db } from '../utils/firebase';
import {
  setDoc,
  serverTimestamp,
  doc,
} from 'firebase/firestore';

import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { auth } from '../utils/firebase';

import { useHistory } from 'react-router-dom';

//redux
import { useSelector } from 'react-redux';
// This is for Tab in the Login & Register Modal
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Navbar({ isAuth }) {
  // user deatails
  const user = useSelector((state) => state.user);
  console.log(user);
  const history = useHistory();
  const [payload, setPayload] = React.useState({
    fullname: '',
    email: '',
    contact: '',
    password: '',
    loginEmail: '',
    loginPassword: '',
  });

  const phoneFormat =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  // For Modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setPayload({
      fullname: '',
      email: '',
      contact: '',
      password: '',
      loginEmail: '',
      loginPassword: '',
    });
  };



  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Drawer - Menu Link
  const [linkDrawer, setLinkDrawer] = React.useState(false);

  // Toggle Drawer - Link Drawer
  const toggleDrawerLink = (open) => (e) => {
    setLinkDrawer(open);
  };

  // handle
  const handleLogin = (e) => {
    signInWithEmailAndPassword(auth, payload.loginEmail, payload.loginPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        const emailVerified = user.emailVerified;

        if (emailVerified === true) {
         alert("Login Successfully");
        } else {
          auth.signOut();
          alert('Your Email is not verified');
          //   setSubmitting(false);
        }

        // Signed in

        // ...
      })
      .catch((error) => {
        // error code
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorCode, errorMessage);
      });
  };

  const handleRegister = () => {
    console.log('Register');
    console.log(payload);
    if (
      payload.fullname === '' ||
      payload.email === '' ||
      payload.contact === '' ||
      payload.password === ''
    ) {
      if (payload.password.length < 6) {
        alert('Password must be at least 6 characters');
      } else {
        alert('Please fill all the fields');
      }
    }
    if (!payload.contact.match(phoneFormat)) {
      alert('Please enter a valid phone number');
    }
    if (payload.contact.length < 10 || payload.contact.length > 10) {
      alert('Please enter 10 digit phone number');
    } else {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, payload.email, payload.password)
        .then((userCredential) => {
          // Signed in
          // ...

          const auth = getAuth();
          sendEmailVerification(auth.currentUser).then(async () => {
            // Email verification sent!

            await setDoc(doc(db, 'Users', userCredential.user.uid), {
              UserUid: userCredential.user.uid,
              UserEmail: payload.email,
              ProfilePicName: '',
              Image: '',
              UserName: payload.fullname,
              UserType: 'Patient',
              ContactNumber: Number('63' + payload.contact),
              Created: serverTimestamp(),
            });

            alert('Email sent');
            auth.signOut();
            //   history.push("/")
            setOpenModal(false);
            setPayload({
              fullname: '',
              email: '',
              contact: '',
              password: '',
              loginEmail: '',
              loginPassword: '',
            });
            // ...
          });
        })
        .catch((error) => {
          // error

          const errorCode = error.code;
          const errorMessage = error.message;
          alert(errorCode, errorMessage);
        });
    }
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut();
    history.push('/');
  };
  const handleChangeUser = (prop) => (e) => {
    setPayload({ ...payload, [prop]: e.target.value });
  };

  const drawerLinkContent = () => (
    <Box>
      {/* Header With Logo */}
      <Box sx={style.drawerLogoContainer}>
        <Box sx={style.logoDrawerWrapper}>
          <Box>
            <img
              alt="drawer_logo"
              src={logo}
              style={{
                width: '60px',
                height: '60px',
                objectFit: 'cover',
                marginRight: '5px',
              }}
            />
          </Box>

          <Box>
            <Typography sx={style.logoTextBig}>
              Health Center Services MIS
            </Typography>
            <Typography sx={style.logoTextSmall}>
              {' '}
             Niugan, Angat, Bulacan
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* If There's No Account Logged In */}
      <Box sx={isAuth === true ? style.noLoginContainer : style.loginContainer}>
        <Typography sx={style.loginTextDrawer}>
          Hello, Please Login your Account to Continue.
        </Typography>
        <Button
          variant="contained"
          sx={style.drawerLoginButton}
          onClick={handleOpenModal}
        >
          Login
        </Button>
      </Box>

      {/* If There's An Account Logged In */}
      <Box sx={isAuth === true ? style.loginContainer : style.noLoginContainer}>
        <Typography sx={style.profileDrawerName}>
          {user.currentUserData[0].UserName}
        </Typography>

        <Box sx={style.perLink}>
          <Link to="/profile" style={{ textDecoration: 'none' }}>
            <Typography sx={style.perLinkText}>My Profile</Typography>
          </Link>
        </Box>
      </Box>

      {/* Links */}
      <Box sx={style.drawerLinkContainer}>
        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography sx={style.perLinkText}>Home</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/about" style={{ textDecoration: 'none' }}>
            <Typography sx={style.perLinkText}>About</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/appointment" style={{ textDecoration: 'none' }}>
            <Typography sx={style.perLinkText}>Appointment</Typography>
          </Link>
        </Box>


        <Box sx={style.perLink}>
          <Link to="/contact" style={{ textDecoration: 'none' }}>
            <Typography sx={style.perLinkText}>Contact Us</Typography>
          </Link>
        </Box>

        <Box sx={isAuth === true ? style.perLink : style.noLoginContainer}>
          <Typography sx={style.logout} onClick={handleLogout}>
            Logout
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      {/* Main container ng Navbar */}
      <Box sx={style.mainContainer}>
        {/* Logo, Link and other stuffs container */}
        <Box sx={style.middleContainer}>
          {/* Logo Container */}
          <Box sx={style.logoContainer}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt="logo"
                src={logo}
                style={{
                  width: '60px',
                  height: '60px',
                  objectFit: 'cover',
                  marginRight: '5px',
                }}
              />
            </Box>

            <Box sx={style.logoTitle}>
              <Typography sx={style.logoTextBig}>
                Health Center Services
              </Typography>
              <Typography sx={style.logoTextBig}>
                Management Information System
              </Typography>
              <Typography sx={style.logoTextSmall}>
                (HecSeMIS) of Niugan, Angat, Bulacan
              </Typography>
            </Box>

            <Box sx={style.alternateLogoTitle}>
              <Typography sx={style.logoTextBig}>
                Health Center Services MIS
              </Typography>
              <Typography sx={style.logoTextSmall}>
                {' '}
                Niugan, Angat, Bulacan
              </Typography>
            </Box>
          </Box>

          {/* Links and Button Container */}
          <Box sx={style.buttonAndStuff}>
            {/* Nav Links */}
            <Box>
              <List sx={style.linkList}>
                <ListItem>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <Typography sx={style.navLink}>Home</Typography>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link to="/about" style={{ textDecoration: 'none' }}>
                    <Typography sx={style.navLink}>About</Typography>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link to="/appointment" style={{ textDecoration: 'none' }}>
                    <Typography sx={style.navLink}>Appointment</Typography>
                  </Link>
                </ListItem>


                <ListItem>
                  <Link to="/contact" style={{ textDecoration: 'none' }}>
                    <Typography sx={style.navLink}>Contact Us</Typography>
                  </Link>
                </ListItem>
              </List>
            </Box>

            {/* Login Button | Profile Avatar*/}
            <Box sx={style.buttonProfileContainer}>
              {/* If No Account Logged In */}
              {isAuth === false ? (
                <Button
                  variant="contained"
                  sx={style.loginButton}
                  onClick={handleOpenModal}
                >
                  Login
                </Button>
              ) : (
                <List sx={style.linkList}>
                  <ListItem>
                    <Link to="/profile" style={{ textDecoration: 'none' }}>
                      <Typography sx={style.navLinkProfile}>
                        My Profile
                      </Typography>
                    </Link>
                    <Box sx={{ ml: "20px" }}>
                      <Avatar src={user.currentUserData[0].Image} alt="profile_pic" />
                    </Box>
                  </ListItem>
                </List>
              )}
            </Box>
          </Box>

          {/* Menu Icon Responsive */}
          <Box sx={style.menuContainer}>
            <IconButton onClick={toggleDrawerLink(true)}>
              <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Modal For Login and Register - Start Here */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={style.modalBox}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Login" {...a11yProps(0)} sx={style.logregTabs} />
            <Tab label="Register" {...a11yProps(1)} sx={style.logregTabs} />
          </Tabs>

          {/* Login Tab */}
          <TabPanel value={value} index={0}>
            <Box>
              <Box>
                <Typography sx={style.modalBig}>Account Login</Typography>
                <Typography sx={style.modalSmall}>
                  To continue transaction, please login your credentials. Thank
                  you.
                </Typography>
              </Box>

              <Box sx={style.textContainer}>
                <Typography sx={style.modalLabel}>Email</Typography>
                <TextField
                  sx={style.textField}
                  value={payload.loginEmail}
                  onChange={handleChangeUser('loginEmail')}
                />
              </Box>

              <Box sx={style.textContainer}>
                <Typography sx={style.modalLabel}>Password</Typography>
                <TextField
                  sx={style.textField}
                  type="password"
                  value={payload.loginPassword}
                  onChange={handleChangeUser('loginPassword')}
                />
              </Box>

              {/* Login Button */}
              <Box sx={style.textContainer}>
                <Button
                  variant="contained"
                  sx={style.loginAccountButton}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </Box>

              {/* Forgot Password */}
              <Box sx={style.forgotPasswordContainer}>
                <Typography sx={style.modalLabel}>Forgot Password?</Typography>

                {/* Forgot Password Link */}
                <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                  <Typography sx={style.forgotpassLink}>Click Here</Typography>
                </Link>
              </Box>
            </Box>
          </TabPanel>

          {/* Register Tab */}
          <TabPanel value={value} index={1}>
            <Box>
              <Box>
                <Typography sx={style.modalBig}>Create an Account</Typography>
                <Typography sx={style.modalSmall}>
                  Please Fill All The Fields.
                </Typography>
              </Box>

              <Box sx={style.textContainer}>
                <Typography sx={style.modalLabel}>Full Name</Typography>
                <TextField
                  sx={style.textField}
                  value={payload.fullname}
                  onChange={handleChangeUser('fullname')}
                />
              </Box>

              <Box sx={style.textContainer}>
                <Typography sx={style.modalLabel}>Email</Typography>
                <TextField
                  sx={style.textField}
                  value={payload.email}
                  onChange={handleChangeUser('email')}
                />
              </Box>

              <Box sx={style.textContainer}>
                <Typography sx={style.modalLabel}>Contact No.</Typography>
                <TextField
                  sx={style.textField}
                  value={payload.contact}
                  defaultValue="9"
                  onChange={handleChangeUser('contact')}
                />
              </Box>

              <Box sx={style.textContainer}>
                <Typography sx={style.modalLabel}>Password</Typography>
                <TextField
                  sx={style.textField}
                  type="password"
                  value={payload.passsword}
                  onChange={handleChangeUser('password')}
                />
              </Box>

              {/* Register Button */}
              <Box sx={style.textContainer}>
                <Button
                  variant="contained"
                  sx={style.loginAccountButton}
                  onClick={handleRegister}
                >
                  Register
                </Button>
                
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </Modal>
      {/* Modal For Login and Register - End Here */}

      {/* Drawer For Menu Links Responsive - Start Here */}
      <Drawer
        anchor={'right'}
        open={linkDrawer}
        onClose={toggleDrawerLink(false)}
        PaperProps={{
          sx: {
            background: '#fff',
            width: 300,
          },
        }}
      >
        {drawerLinkContent()}
      </Drawer>
      {/* Drawer For Menu Links Responsive - End Here */}
    </div>
  );
}

export default Navbar;
