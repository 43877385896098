const style = {
    headerBox: {
        padding: '5px',
    },

    headerText: {
        fontFamily: 'semibold',
        fontSize: '16px',
        color: '#000',
    },

    headerSubText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#8e8e8e',
    },

    tableContainer: {
        border: '1px solid #8e8e8e',
        margin: '10px 0px',
        padding: '5px',
    },

    tableHead: {
        backgroundColor: '#16304D',
    },

    tableCell: {
        fontFamily: 'semibold',
        fontSize: '13px',
        color: '#fff',
    },

    tablePaperContainer: {
        boxShadow: 0,
        borderRadius: 0,
    },

    doctorTable: {
        minWidth: 650,
        // backgroundColor: '#16304D',
        backgroundColor: '#fff',
        borderRadius: 0,
    },

    tableCellDetails: {
        fontFamily: 'poppins',
        fontSize: '13px',
        color: '#000',
    },

    statusBookedPending: {
        fontFamily: 'poppins',
        fontSize: '13px',
        backgroundColor: 'orange',
        padding: '5px',
        color: '#fff',
        borderRadius: '8px',
        textAlign: 'center',
    },
    statusBookedWaiting: {
        fontFamily: 'poppins',
        fontSize: '13px',
        backgroundColor: 'orange',
        padding: '5px',
        color: '#fff',
        borderRadius: '8px',
        textAlign: 'center',
    },
    statusBookedDone: {
        fontFamily: 'poppins',
        fontSize: '13px',
        backgroundColor: 'green',
        padding: '5px',
        color: '#fff',
        borderRadius: '8px',
        textAlign: 'center',
    },

    statusPending: {
        fontFamily: 'poppins',
        fontSize: '13px',
        backgroundColor: '#0d97a1',
        padding: '5px',
        color: '#fff',
        borderRadius: '8px',
        textAlign: 'center',
    },

    statusDone: {
        fontFamily: 'poppins',
        fontSize: '13px',
        backgroundColor: '#08750f',
        padding: '5px',
        color: '#fff',
        borderRadius: '8px',
        textAlign: 'center',
    },

    cancelButton: {
        backgroundColor: '#871c1c',
        fontFamily: 'poppins',
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: '12px',

        '&:hover': {
            backgroundColor: '#700404',
        },
    },

    mainSearchContainer: {
        margin: '10px 0px 15px 0px',
    },

    searchContainer: {
        width: {
            sm: '50%',
            md: '40%',
        },
        display: 'flex',
        alignItems: 'center',
    },

    textLabel: {
        fontFamily: 'poppins',
        fontSize: '14px',
        marginRight: '10px',
    },

    searchTxt: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#cccccc',
            },
            '&:hover fieldset': {
                borderColor: '#479923',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #479923',
            },

            fontFamily: 'Poppins',
            fontSize: '13px',
            padding: '10px',
            height: '35px',
            borderRadius: 0,
        },

        width: '100%',
        backgroundColor: '#fff',

        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
            backgroundColor: '#fff',
        },
    },

    emptyNotice: {
        width: '90%',
        backgroundColor: '#f5c3a2',
        border: '1px solid #fa883c',
        borderRadius: '5px',
        padding: '10px',
        margin: '10px 0px 10px 0px',
    },

    deleteNotice: {
        width: '90%',
        backgroundColor: '#aef5be',
        border: '1px solid #21ad42',
        borderRadius: '5px',
        padding: '10px',
        margin: '10px 0px 10px 0px',
    },

    textNotice: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

}
export default style;