const style = {
    mainContainer: {
        width: {
            xs: '95%',
            md: '80%',
        },

        margin: 'auto',
    },

    banner: {
        width: '100%',
        height: '2vh',
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            xs: '10px',
            md: '100px',
        },
    },

    promotionalSpaceContainer: {
        marginTop: '50px',
    },

    titleContainer: {
        textAlign: 'center',
        marginBottom: '10px',
        marginTop: '50px',
    },

    promotionalTitle: {
        fontFamily: 'semibold',
        fontSize: '18px',
        position: 'relative',
        marginBottom: '3rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#479923',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
        },
    },

    promotionalTitleSub: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '10px 0px 20px 0px',
        lineHeight: '25px',
    },

    doctorContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    doctorCard: {
        backgroundColor: '#fff',
        boxShadow: 0,
        borderRadius: 0,
        minWidth: 250,
    },

    doctorName: {
        fontFamily: 'semibold',
        fontSize: '16px',
        color: '#000',
        textAlign: 'center',
    },

    doctorPosition: {
        fontFamily: 'semibold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '14px',
        color: '#595959',
        textAlign: 'center',
    },

    doctorDescription: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        textAlign: 'center',
        marginTop: '5px',

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },

    socmedMainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    socmedBox: {
        border: '1px solid #595959',
        height: '30px',
        width: '30px',
        color: '#595959',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'all ease .5s',
        margin: '2px',

        '&:hover': {
            color: '#fff',
            backgroundColor: '#16304D',
        }, 
    },

    socmedIcon: {
        fontSize: '20px',
    },
}
export default style;