const style = {
    mainContainer: {
        width: {
            xs: '95%',
            md: '80%',
        },

        margin: 'auto',
    },

    banner: {
        width: '100%',
        height: '2vh',
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            xs: '10px',
            md: '100px',
        },
    },

    titleContainer: {
        textAlign: 'center',
        marginBottom: '10px',
        marginTop: '50px',
    },

    promotionalSpaceContainer: {
        marginTop: '200px',
    },

    promotionalTitle: {
        fontFamily: 'semibold',
        fontSize: '18px',
        position: 'relative',
        marginBottom: '3rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#479923',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
        },
    },

    promotionalTitleSub: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '10px 0px 20px 0px',
        lineHeight: '25px',
    },

    gridPic: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    promotionalTextContainer: {
        padding: '5px',
    },

    promotionalTextBig: {
        fontFamily: 'semibold',
        fontSize: '25px',
        margin: '20px 0px 20px 0px',
    },

    promotionalTextSmall: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '20px 0px 20px 0px',
        lineHeight: '25px',
    },

    mauButton: {
        height: '50px',
        textTransform: 'capitalize',
        fontFamily: 'poppins',
        boxShadow: 0,
    },

    serviceGridContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '50px',
    },

    serviceBox: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    serviceTitle: {
        fontFamily: 'semibold',
        fontSize: '15px',
        textAlign: 'center',
    },

    serviceText: {
        fontFamily: 'poppins',
        color: '#595959',
        fontSize: '14px',
        textAlign: 'center',
    },

    checkIcon: {
        fontSize: '50px',
        marginBottom: '5px',
        color: '#bf4137',
    },

    pregnantIcon: {
        fontSize: '50px',
        marginBottom: '5px',
        color: '#37a0bf',
    },

    medicineIcon: {
        fontSize: '50px',
        marginBottom: '5px',
        color: '#37bf6c',
    },

    familyIcon: {
        fontSize: '50px',
        marginBottom: '5px',
        color: '#bf9037',
    },

    dotDivider: {
        color: '#479923',
        textAlign: 'center',
        fontSize: '25px',
    },

    breadcrumbContainer: {
        width: '95%',
        margin: 'auto',
    },

}
export default style;