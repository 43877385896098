const style = {
    headerBox: {
      padding: "5px",
    },
  
    headerText: {
      fontFamily: "semibold",
      fontSize: "16px",
      color: "#000",
    },
  
    headerSubText: {
      fontFamily: "poppins",
      fontSize: "14px",
      color: "#8e8e8e",
    },
  };
  
  export default style;
  