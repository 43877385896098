import banner from '../image/homepic.jpg'

const style = {
    mainBody: {
        width: {
            xs: '95%',
            md: '80%',
        },
        margin: 'auto',
    },

    banner: {
        backgroundImage: `url(${banner})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '70vh',
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            xs: '10px',
            md: '40px',
        },
    },

    bannerTextContainer: {
        width: '95%',
        margin: 'auto',
    },

    bannerTextBig: {
        fontFamily: 'bold',
        lineHeight: '45px',
        fontSize: '40px',
        color: 'white',
    },

    bannerTextSmall: {
        fontFamily: 'semibold',
        fontSize: '14px',
        marginTop: '10px',
        color: 'white',
    },

    appButton: {
        boxShadow: 0,
        marginTop: '15px',
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        height: '40px',
        backgroundColor: '#16304d',

        '&:hover': {
            backgroundColor: '#1d3d61',
            boxShadow: 0,
        },
    },

    promotionalSpaceContainer: {
        marginTop: '50px',
    },

    gridPic: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    promotionalTextContainer: {
        padding: '5px',
    },

    promotionalTextBig: {
        fontFamily: 'semibold',
        fontSize: '25px',
        margin: '20px 0px 20px 0px',
    },

    promotionalTitle: {
        fontFamily: 'semibold',
        fontSize: '18px',
        position: 'relative',
        marginBottom: '3rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#479923',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
        },
    },

    promotionalTitleSub: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '10px 0px 20px 0px',
        lineHeight: '25px',
    },

    promotionalTextSmall: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        margin: '20px 0px 20px 0px',
        lineHeight: '25px',
    },

    mauButton: {
        height: '50px',
        textTransform: 'capitalize',
        fontFamily: 'poppins',
        boxShadow: 0,
    },

    titleContainer: {
        textAlign: 'center',
        marginBottom: '10px',
        marginTop: '100px',
    },

    newsContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '50px',
        marginBottom: '50px',
    },

    boxCard: {
        margin: 1,
    },

    cardMain: {
        maxWidth: 300,
        borderRadius: 0,
        boxShadow: 3,
        backgroundColor: '#fff',

        '&:hover': {
            boxShadow: 10,
        },
    },

    cardTitle: {
        fontFamily: 'semibold',
        fontSize: '15px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        width: '260px',
    },

    cardDescription: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        width: '260px',
    },

    servicesContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    servicesOfferedContainer: {
        marginTop: '50px',
        marginBottom: '50px',
        width: {
            lg: '85%',
        },
        margin: 'auto',
    },

    gridService: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    cardServices: {
        display: 'flex',
        backgroundColor: '#fff',
        boxShadow: 0,
        borderRadius: 0,
    },

    cardTitleService: {
        fontFamily: 'semibold',
        fontSize: '15px',
        color: '#000',
        marginBottom: '10px',
    },

    cardDescriptionService: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#595959',
    },

    slideCommentMain: {
        width: '100%',
        backgroundColor: 'red',
    },

    slideContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: '90%',
            md: '60%',
            lg: '60%',
        },
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    quoteLogo: {
        fontFamily: 'bold',
        fontSize: '100px',
        display: {
            xs: 'none',
            md: 'flex',
        }
    },

    comment: {
        fontFamily: 'poppins',
        fontSize: {
            xs: '13px',
            md: '14px',
        },
        textAlign: 'center',
        color: '#595959',
    },

    commentorAndPosition: {
        fontFamily: 'semibold',
        fonSize: '14px',
        textAlign: 'center',
        marginTop: '10px',
        fontStyle: 'italic',
    },

    commentAvatar: {
        width: '90px',
        height: '90px',
        marginTop: '20px',
        display: {
            xs: 'none',
            md: 'block',
        },
    },

    questionContainer: {
        margin: '50px 0px 50px 0px',
    },

    mapContainer: {
        padding: '10px',
    },

    infoContainer: {
        padding: '10px',
    },

    headerContainer: {
        backgroundColor: '#16304d',
        padding: '10px',
    },

    textHeader: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#fff',
        textAlign: 'center',
    },

    textBoxInfoContainer: {
        display: 'flex',
        marginTop: '10px',
    },

    textboxFirst: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '2px solid #16304d',
            },

            fontFamily: 'Poppins',
            fontSize: '14px',
            padding: '5px',
            borderRadius: 0,
        },

        width: '100%',
        

        '& .MuiInputBase-input': {
            color: '#000',
            padding: '5px',
            height: '30px',
        },
    },

    submitButton: {
        width: '100%',
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        boxShadow: 0,
        backgroundColor: '#16304d',

        '&:hover': {
            boxShadow: 0,
            backgroundColor: '#7d1a0b',
        },
    },
}
export default style;