import React from "react";

// Import Styling Page
import style from "../styles/ProfileAppointment";

// Import Material UIs
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,

} from "@mui/material";
import { format } from "date-fns";

import { useSelector } from "react-redux";

import { db } from "../utils/firebase";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

function ProfileAppointment({ isAuth }) {
  const user = useSelector((state) => state.user);

  const cancelAppointment = async (id) => {


    const auth = getAuth();
    await updateDoc(doc(db, "Appointment", id), {
      PatientUser: "",
      Status: "Pending",
      Reason: "",
      Message: "",
    });

    await addDoc(collection(db, "History"), {
      Title: `Patient ${user.currentUserData[0].UserName} Cancel the Appointment`,
      CreatedUser: auth.currentUser.uid,
      Created: serverTimestamp(),
    });

    alert("Cancel Appointment Successfully");
   
  };


  return (
    <div>
      <Box sx={style.headerBox}>
        <Typography sx={style.headerText}>My Appointment Request</Typography>
      </Box>

      {/* Notice */}
      {/* Empty Notice */}
      {/*
            <Box sx={style.emptyNotice}>
                <Typography sx={style.textNotice}>No Appointment Yet.</Typography>
            </Box> */}

      {/* Delete Notice */}
      {/*
            <Box sx={style.deleteNotice}>
                <Typography sx={style.textNotice}>Appointment Successfully Cancelled.</Typography>
            </Box>  */}

      {/* Search */}
      {/* <Box sx={style.mainSearchContainer}>
        <Box sx={style.searchContainer}>
          <Typography sx={style.textLabel}>Search:</Typography>
          <TextField sx={style.searchTxt} />
        </Box>
      </Box> */}

      {/* All Table Container */}
      <Box sx={style.tableContainer}>
        <TableContainer component={Paper} sx={style.tablePaperContainer}>
          <Table sx={style.doctorTable}>
            {/* Table Row - Table Head */}
            <TableHead sx={style.tableHead}>
              <TableRow>
                <TableCell sx={style.tableCell}>Appointment ID.</TableCell>
                <TableCell sx={style.tableCell}>Name</TableCell>
                <TableCell sx={style.tableCell} align="left">
                  Appointment Date
                </TableCell>
                <TableCell sx={style.tableCell} align="left">
                  Appointment Day
                </TableCell>
                <TableCell sx={style.tableCell} align="left">
                  Appointment Time
                </TableCell>
                <TableCell sx={style.tableCell} align="left">
                  Status
                </TableCell>
                <TableCell sx={style.tableCell} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Content */}
            <TableBody>
              {user.appointments
                .filter(
                  (appointment) =>
                    appointment.PatientUser === user.currentUserData[0].id
                )
                .map((appointment) => (
                  <TableRow>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                      {appointment.id}
                    </TableCell>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                      {appointment.FullName}
                    </TableCell>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                     {format(new Date((appointment.Date.seconds*1000)), 'PPP')}
                    </TableCell>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                      {format(new Date((appointment.Date.seconds*1000)), 'EEEE')}
                    </TableCell>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                      {format(new Date((appointment.Date.seconds*1000)), 'KK:mm b')}
                    </TableCell>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={
                          appointment.Status === "Pending"
                            ? style.statusBookedPending
                            : appointment.Status === "Waiting to be Confirmed"
                            ? style.statusBookedWaiting
                            : appointment.Status === "Doctor Accepted"
                            ? style.statusBookedDone
                            : style.statusBookedDone
                        }
                      >
                        {appointment.Status}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={style.tableCellDetails}
                      component="th"
                      scope="row"
                    >
                      <Button 
                      onClick={() => cancelAppointment(appointment.id)}
                      disabled={ 
                            appointment.Status === "Waiting to be Confirmed"
                            ? false
                            : appointment.Status === "Doctor Accepted"
                            ? true
                            : true} sx={style.cancelButton}>
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default ProfileAppointment;
