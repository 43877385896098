import React from 'react'
import { Link } from 'react-router-dom'

// Import Styling Page
import style from '../styles/Footer'

// Import Image
import logo from '../image/Angat_Logo.png'
import doh from '../image/doh.png'
import green from '../image/it green.png'
import orange from '../image/it orange.png'

// Import Material UIs
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';

function Footer() {
    return (
        <div>
            <Box sx={style.mainFooterContainer}>
                <Box sx={style.stuffContainer}>

                    {/* Main Footer Container */}
                    <Grid container>
                        {/* Logo Container */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.logoBox}>

                                <Box sx={style.logoHealth}>
                                    <img
                                        alt="footerLogo"
                                        src={logo}
                                        style={{
                                            width: '70px',
                                            height: '70px',
                                            objectFit: 'cover',
                                        }}
                                    />

                                    <img
                                        alt="dohLogo"
                                        src={doh}
                                        style={{
                                            width: '65px',
                                            height: '65px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>

                                <Typography sx={style.footerLogoTextBig}>Health Center Services Management Information System</Typography>
                                <Typography sx={style.footerLogoTextSmall}>{"(HecSeMIS)"} of Niugan, Angat, Bulacan</Typography>
                            </Box>
                        </Grid>

                        {/* Column 1 */}
                        <Grid item xs={12} md={2.8}>
                            <Box sx={style.footerBox}>
                                <Typography sx={style.footerTitle}>Our Clinic</Typography>

                                <Link to="/about" style={{ textDecoration: 'none', }}>
                                    <Typography sx={style.footerLinkText}>About Us</Typography>
                                </Link>

                                <Link to="/developers" style={{ textDecoration: 'none', }}>
                                    <Typography sx={style.footerLinkText}>Developers</Typography>
                                </Link>

                                <Link to="/about" style={{ textDecoration: 'none', }}>
                                    <Typography sx={style.footerLinkText}>Our Services</Typography>
                                </Link>

                                <Link to="/appointment" style={{ textDecoration: 'none', }}>
                                    <Typography sx={style.footerLinkText}>Book An Appointment</Typography>
                                </Link>
                            </Box>
                        </Grid>

                        {/* Column 2 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.footerBox}>
                                <Typography sx={style.footerTitle}>Contact Us</Typography>

                                <Typography sx={style.footerPlainText}>Niugan, Angat, Bulacan</Typography>

                                <Typography sx={style.footerPlainText}>0999 351 4080 | {"(044)"} 837 4421</Typography>

                                <Typography sx={style.footerPlainText}>niugan.clinic@gmail.com</Typography>

                                <Typography sx={style.footerPlainText}>Weekdays 08:00am - 05:00am</Typography>

                            </Box>
                        </Grid>

                        {/* Column 3 */}
                        <Grid item xs={12} md={3}>
                            <Box sx={style.footerBox}>
                                <Typography sx={style.footerTitle}>In partnership with</Typography>

                                <Box sx={style.logoHealth}>
                                    <img
                                        alt="footerLogo"
                                        src={green}
                                        style={{
                                            width: '70px',
                                            height: '70px',
                                            objectFit: 'cover',
                                        }}
                                    />

                                    <img
                                        alt="dohLogo"
                                        src={orange}
                                        style={{
                                            width: '65px',
                                            height: '65px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Box>

                                <Typography sx={style.footerPlainText}>This HECSEMIS is a Capstone Project under the BSIT Services Program of BulSU - Bustos Campus</Typography>

                            </Box>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </div>
    )
}

export default Footer