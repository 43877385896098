const style = {
    mainContainer: {
        width: {
            xs: '95%',
            md: '80%',
        },

        margin: 'auto',
        minHeight: '50vh',
        // backgroundColor: 'pink',
    },

    banner: {
        width: '100%',
        height: '2vh',
        display: 'flex',
        alignItems: 'center',
        marginTop: {
            xs: '10px',
            md: '100px',
        },
    },

    tabsStyle: {
        borderRight: 1,
        borderColor: 'divider',
    },

    tabLabel: {
        color: '#8a8a8a',
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'flex-start',
        textAlign: 'left',
        justifyContent: 'center',

    },
}
export default style;